import React from 'react';

/*
 * Rather than hide this, a better way would
 * be a tooltip and even in the unselected lang.
 * But, it's not recommended to call the API
 * directly, so consider removing entirely also.
 */

const Logo = () => {
  return (
		<svg viewBox="0 0 2699.4 369.3">
		<g transform="translate(0.000000,1531.000000) scale(0.100000,-0.100000)">
			<path d="M14021.7,15297.5c0-5,5-12,11-16c17-10-11-24-51-24c-20,0-41,5-48,12c-9,9-17,9-34,0c-13-7-32-12-43-11c-11,0-67-2-125-5
				c-58-4-144-8-192-10c-47-2-92-7-98-11c-7-5-9-20-6-40c7-36-8-55-27-36c-6,6-19,11-29,11s-18,6-18,14c0,19,23,33,28,18
				c2-7,8-12,13-12c6,0,7,6,2,13c-4,6-8,18-9,25c-4,23-12,24-23,3c-12-21-34-19-60,6c-11,11-16,11-28,1c-17-13-19-14-71-16
				c-19-1-31-6-27-12c3-5-4-10-17-10s-58-3-100-6c-101-8-128-2-121,28c4,21,3,21-9,6c-19-23-99-50-113-38c-6,5-16,6-21,1
				c-12-11-109-38-164-47c-25-3-51-11-58-17s-52-12-98-13c-61-2-91-7-103-18c-19-18-61-22-61-6c0,12-48,7-77-8c-12-7-29-12-37-12
				c-9,0-16-7-16-15c0-11-11-15-40-15c-22,0-40,5-40,10c0,16-37,12-44-5c-3-8-14-15-24-15s-29-11-42-25c-21-23-24-23-36-7
				c-8,10-13,12-14,5c0-16-32-17-46-3c-8,8-15,6-24-5c-7-8-28-15-50-15c-25,0-40-5-44-15c-4-13-16-15-56-10c-37,4-56,1-75-11
				c-15-10-25-12-25-5c0,6-5,11-11,11s-8-9-4-20c6-20-9-28-20-10c-3,5-29-13-57-40s-54-47-59-45c-4,3-10,1-14-5c-3-5-12-10-20-10
				s-29-13-47-30c-31-29-88-42-88-20c0,6,9,10,20,10s20,7,20,15c0,39-60,4-75-44c-3-12-14-21-23-21c-10,0-28-7-41-16
				c-17-12-26-13-34-5c-17,17-26,13-20-9c3-11,1-21-3-21c-5-1-18-3-29-4s-37-18-58-39c-45-44-69-47-172-25c-27,6-70,13-95,14
				c-40,3-117,12-270,30c-25,3-71,10-103,17c-43,8-64,9-76,1c-11-7-23-8-32-3c-14,9-62,16-174,25c-33,2-87,9-120,15
				c-33,5-96,10-140,11c-44,0-107,5-140,10s-89,12-125,15c-36,4-70,11-76,16c-6,4-47,11-91,13c-74,5-82,4-105-18c-56-52-40-135,27-151
				c34-8,24-7,290-35c110-11,216-26,235-32c39-12,88-18,170-22c30-1,60-5,67-10c6-4,42-9,80-12c37-2,124-12,193-21s145-17,170-18
				c94-5,246-30,259-42c12-11,12-23-3-81c-31-131,8-281,98-373c91-92,169-149,209-150c9,0,17-4,17-10c0-5,9-10,20-10s23-7,26-15
				c4-8,12-15,20-15s22-4,32-9c17-9,178-60,260-83c52-14,83-48,54-59c-20-7-72-71-72-88c0-5-11-21-25-35s-25-29-25-34s-9-17-20-27
				s-20-26-20-36s-9-20-19-24c-11-3-23-16-27-27c-3-12-22-36-40-54c-19-17-34-38-34-47c0-8-7-17-15-21c-8-3-15-11-15-19
				c0-7-8-22-17-33c-10-11-25-32-33-46c-37-64-86-128-98-128c-9,0-10-5-2-19c13-25,13-41,0-21c-9,13-14,11-35-13c-14-15-25-35-25-43
				s-5-14-11-14c-7,0-9-11-5-27c3-16,3-22,0-15c-8,17-24,5-17-13c3-8,0-15-6-15s-27-25-46-55c-20-30-40-55-45-55s-14-18-20-40
				s-15-40-20-40s-15-11-22-24c-41-74-53-94-79-127c-16-20-29-43-29-51s-11-27-24-43c-13-15-33-47-45-70c-12-24-29-49-38-56
				c-9-8-21-25-27-39s-31-55-54-92l-44-68l-116,5c-65,2-139,9-167,15c-27,5-81,15-120,21c-38,7-74,15-80,19c-9,8-80,30-116,36
				c-9,2-29,9-45,15c-16,7-36,11-44,10c-9-1-27,10-40,24s-31,25-41,25c-9,0-22,7-29,15s-20,15-30,15c-22,0-170,96-170,110
				c0,5-9,10-21,10s-45,25-80,63c-99,102-164,208-191,309c-11,44-11,50,10,75c12,16,26,37,31,48s27,31,50,45c22,14,41,28,41,33
				c0,4,7,7,15,7s19,9,26,21c7,15,17,19,32,15c11-3,26-1,32,4s40,21,76,34c35,13,78,30,94,37c17,7,55,18,85,25s73,17,95,23
				c22,5,61,15,87,21s64,23,84,37c27,17,46,23,68,20c39-6,76,8,76,28c0,19,19,20,26,0c5-12,9-11,26,4c12,12,28,17,40,13
				c14-3,19-1,14,6c-3,6-1,14,5,18c8,4,7,9-2,15c-24,15-39,10-39-12c0-12-5-19-12-16c-7,2-12,15-11,28s-1,18-3,12c-8-18-88-17-81,0
				c4,10-7,13-47,11c-28-2-64,2-81,8c-42,14-177-3-239-32c-35-16-69-22-118-22c-37-1-68-5-68-9c0-5-10-9-23-9c-12,0-31-6-42-14
				c-11-7-33-16-50-19c-85-16-125-30-125-43c0-8-10-14-26-14c-35,0-126-69-119-90c5-15-44-70-62-70c-13,0-53-44-53-57c0-6-7-16-16-24
				c-9-7-14-22-11-36c2-13,0-26-5-29c-9-6,0-95,14-133c5-13,21-40,37-60s32-56,36-81s16-53,26-63c11-10,19-22,19-27c0-12,58-69,65-62
				c3,3,5-2,5-11c0-21,56-72,78-72c9,0,23-13,30-28c14-29,83-79,104-75c7,2,13-3,14-9c1-7,2-17,3-23c0-5,9-10,20-10c10,0,24-6,31-14
				c6-8,18-12,26-9s14-1,14-11c0-9,6-16,14-16s39-13,69-30c30-16,57-27,60-23c4,3,9-2,13-11c3-9,17-16,30-16s24-5,24-10
				c0-6,12-15,28-20c32-12,70-28,96-42c11-6,52-12,93-13c61-3,77-7,91-25c12-14,34-23,72-29c30-4,64-12,75-19c32-18,177-35,186-21
				c5,8,8,8,13,0c4-6,24-11,44-11s56-7,80-15c42-15,107-8,155,17c10,5,62,11,114,14c53,3,104,9,113,14s62,11,118,14
				c75,4,112,10,140,25c20,11,55,22,77,24c22,3,71,19,109,37c39,19,75,33,82,33s10,6,7,14c-6,16,19,17,35,1c10-10,14-10,21,0
				c14,24,80,53,106,46c16-4,34,1,52,14c50,35,143,82,163,82c21,0,163,72,191,96c8,8,21,14,27,14c17,0,26-21,13-29c-6-4-17-35-23-69
				c-10-53-9-69,7-117c11-30,22-55,26-55s9-8,13-17c7-22,25-50,51-80c19-22,22-22,73-8c42,11,174,86,202,115c3,3,27,16,53,30
				c27,14,54,32,60,41c7,9,35,28,64,42c29,15,56,32,60,39c10,16,86,68,99,68c9,0,65,46,110,90c12,11,27,20,35,20c22,1,52,27,44,39
				c-3,7,2,11,14,11s21,4,21,9s12,14,28,20c28,11,34,15,115,69c54,35,71,32,80-16c15-71,21-89,43-119c13-17,24-38,24-46
				c0-31,108-97,159-97c33,0,81-37,81-63c0-39,64-65,170-68c84-2,321,48,340,72c3,3,23,12,45,19c102,33,177,63,208,84
				c18,13,47,26,63,30s58,21,94,38l64,31l16-24c8-13,30-41,48-62c17-21,32-42,32-46s11-13,25-19c29-13,179-8,219,8c13,5,27,6,31,2
				c3-4,11,1,16,11c6,9,16,17,22,17c7,0,20,8,28,18c23,25,52,47,72,55c9,3,17,11,17,17c0,5,7,10,15,10s15,6,15,14s3,16,8,18
				c4,2,49,50,100,108c52,58,106,119,121,135c15,17,54,66,86,110c33,44,65,85,71,90c6,6,21,25,34,44c13,18,37,48,55,65
				c17,17,34,39,38,49c3,9,13,17,20,17c8,0,22,9,32,20s38,33,62,48c23,15,43,32,43,39c0,6,8,14,18,17c9,3,29,17,44,31
				c29,27,80,49,168,71c144,37,180,48,245,70c81,28,85,28,85,11c0-16-52-67-68-67c-7,0-12-7-12-15s-7-15-15-15s-19-9-24-19
				c-6-10-31-32-55-48c-56-37-156-128-156-142c0-5-9-13-21-16c-16-5-19-12-15-31c6-21,3-24-19-24c-36,0-101-35-121-64
				c-8-14-28-29-43-34c-16-6-39-27-52-48c-23-38-51-68-97-105c-13-10-22-24-20-31c3-7-6-21-19-33c-12-11-38-47-58-79
				c-26-45-32-63-24-73c9-10,7-13-10-13c-12,0-21-6-21-16c0-8-12-31-26-49c-14-19-30-51-34-70c-10-42,6-171,23-192c33-39,52-51,94-57
				c38-5,58,0,126,29c44,19,84,35,89,35s21,9,36,20s36,22,47,24c36,7,134,47,140,57c4,5,14,9,24,9s24,7,31,15c7,9,29,23,49,32
				c36,17,92,52,101,65c3,3,18,10,33,13c15,4,27,11,27,16s4,9,9,9c12,0,41-57,41-81c0-10,12-27,28-38c15-11,34-27,42-34
				c25-23,160-13,315,22c77,18,165,36,197,40c31,5,61,12,68,16s30,9,52,10c36,3,39,1,45-26c3-16,11-29,18-29s30-14,51-31
				c37-30,42-31,109-26c39,3,135,21,215,40c80,20,172,38,205,42c63,7,81,12,181,45c33,11,79,20,101,20c24,0,46,6,53,15s28,23,46,31
				c19,9,34,23,34,30c0,8,4,14,10,14c15,0,40-55,40-88c0-86,71-184,145-198c19-3,86,1,148,9c413,58,436,60,480,48c23-7,49-16,57-21
				c9-4,107-8,218-8s206-3,210-6c5-3,63-8,128-11s155-8,199-11c44-2,204-11,355-19c308-16,550-37,915-81c66-8,151-17,190-19
				c38-3,94-9,123-15c30-5,58-8,62-5c5,3,48-1,95-9c48-7,173-14,278-15c124-2,194-6,198-13s11-2,18,11c15,28,29,27,37-4
				c6-24,25-30,83-27c8,0,36-3,62-7c31-5,52-4,60,3c9,8,10,8,5-1s0-13,13-13c10,0,21,4,23,10s22,6,58-1c111-22,163-23,177-3
				c7,10,13,13,13,7c0-29,62-43,194-44c142-2,174,3,195,33c9,14,17,16,24,9s6-15-3-25c-10-13-9-17,3-22c9-3,20-3,25,0s59,0,119-6
				c88-9,117-9,142,1c17,8,31,17,31,22s5,9,11,9c5,0,8-4,4-9c-10-17,19-26,83-26c51-1,62,2,59,15c-1,8,2,20,9,25c16,14-2,95-22,95
				c-16,0-19,16-3,25c14,9,37-23,45-63s18-46,74-47c59,0,69-19,12-22c-46-1-64-10-45-21c15-10,249-9,259,1c4,4,0,13-9,20
				c-14,10-14,15-2,30c13,18,14,18,24-2c6-11,11-29,11-39c0-18,4-19,36-12c19,5,50,4,67-1c50-14,117-11,123,6c5,13,10,13,33,0
				c25-12,175-19,386-17c22,0,89,1,148,2c60,1,114-3,120-8c21-17,77-25,91-13c12,10,49,13,136,10c14,0,33-8,43-17c17-15,20-15,36,7
				l17,23l15-21c13-20,26-21,334-28c286-6,379-7,438-4c10,1,21,11,26,23c6,17,9,18,14,5c3-8-1-21-8-27c-28-24-2-31,108-30
				c62,0,144-5,182-11c39-6,161-12,271-14c111-1,207-5,213-9c14-9,281-9,316,0c13,3,26,13,28,22c4,15,5,15,6,0c1-19,21-25,21-7
				c0,13,50,63,71,71c9,4,20,23,24,44c7,41-10,64-47,64c-10,0-21,6-24,14c-10,27-33,25-70-5c-26-21-45-28-59-24c-11,3-63,10-115,16
				c-52,5-124,13-160,18c-54,7-67,6-77-7c-11-13-13-13-13-1c0,8,16,30,35,49c21,20,35,44,35,58c0,17,4,23,15,18c8-3,19,3,25,13
				c7,14,6,20-5,25c-19,7-20,56-1,56c8,0,17-6,20-12c3-7,2,5-1,28c-4,29-1,45,11,57c9,8,16,19,16,24s9,26,20,48c15,29,17,42,8,52
				c-8,10-8,24,1,54c15,50,7,79-23,79c-32,0-37,9-18,31c15,17,17,17,34,2s18-15,18,4c0,11-10,28-22,36l-23,16h23c30,1,32,50,2,58
				c-25,7-26,20-2,28c27,8,50,81,34,106c-7,10-12,32-12,49c0,33-25,80-43,80c-6,0-18,7-28,16c-26,24-39,9-39-47c0-37,4-49,15-49
				c22,0,18-17-7-35c-21-14-24-14-34,0c-12,17-29,21-19,5c3-6-4-14-17-19s-33-23-46-40c-12-17-32-31-43-31c-16,0-19-4-14-20
				c8-24-20-39-38-21c-6,6-28,11-48,11c-66,0-80,22-86,130c-5,104-23,163-46,154c-8-3-17,2-20,10c-8,21-61,21-78-1
				c-10-11-14-48-15-122c0-115,7-160,31-177c8-6,15-14,15-18c0-13,44-45,62-46c9,0,20-4,23-10c3-5,15-10,26-10c10,0,19,5,19,10
				c0,6,5,10,10,10c6,0,10-7,10-15c0-25,31-35,59-17c18,12,26,13,29,4c2-7,8-9,13-6c6,3,15,1,22-4c7-6,25-10,40-9c22,2,27,6,23,20
				c-2,10,2,21,9,23c7,3,15,15,18,26s10,17,15,14c6-3,12-1,14,6c3,7,12,9,22,5c14-6,17-15,14-42c-3-23,1-36,10-40c12-4,13-28,8-133
				c-10-186-26-238-82-272c-19-10-34-24-34-30c0-5-7-10-16-10c-8,0-22-16-30-36s-18-33-21-30s-12-2-18-11c-11-15-17-15-39-5
				c-39,17-43,16-56-23c-6-19-15-35-18-35c-11,0-34,17-33,24c2,16,0,17-15,5c-13-10-17-10-25,2c-8,11-14,9-35-15l-25-28l-114,7
				c-119,7-209,0-184-16c11-6,11-11,2-20c-16-16-42-4-53,26c-8,22-16,26-52,26c-109,0-208,5-214,11c-3,4-17,3-30-2c-15-6-43-5-76,2
				c-56,12-151,17-180,10c-10-2-18-11-18-20c0-14-1-14-12,0c-10,12-41,17-128,22c-184,10-220,16-220,36c0,9-18,31-40,49
				c-42,34-51,57-23,63c14,3,17,17,19,90c2,71,0,86-12,81c-8-3-14,0-14,6s5,11,10,11c6,0,10,16,10,35c0,41,21,55,53,36
				c16-10,21-10,28,1c7,10,12,11,29,1c26-17,30-16,30,2c0,9,6,15,13,14c28-3,37,2,37,22c0,17,5,20,20,16c16-4,36,9,80,51
				c50,47,60,62,60,89c0,20-6,36-14,39c-20,8-8,34,15,34c11,0,23-10,28-22l8-22l12,21c15,30,14,41-6,46c-16,3-16,5,2,11
				c69,26,69,26,50,40c-16,12-16,13-1,19c13,5,16,17,12,65c-4,61-24,111-59,150c-16,17-28,21-49,17c-15-4-28-3-28,2s-18,9-40,8
				c-39,0-40-1-55-52c-18-60-45-74-45-23c0,35-2,36-42,13c-28-17-30-21-26-71c5-53-6-77-23-51c-5,8-9,31-9,52c0,35-12,48-25,27
				c-14-23-22-7-24,47c-1,64-2,66-51,82c-30,10-38,9-59-7c-14-10-29-32-33-48c-10-31-11-558-3-821c5-160,5-163-16-163c-11,0-28-4-37-8
				c-10-5-32-3-52,4c-19,7-29,13-22,13c6,1,12,5,12,9c0,10-37,9-47-2c-4-4-1-11,7-16c13-8,13-10,1-10c-8,0-23,8-33,17
				c-14,12-41,18-99,20c-79,3-80,2-74-20c4-12,3-17-2-11c-11,15-50,27-63,19c-6-4-7-13-2-23c5-9,6-28,3-41c-6-23-7-23-24-6
				c-10,10-33,21-50,25l-32,8l38,1c46,1,49,21,3,21c-25,0-40-7-54-24l-19-25l-1,30c-1,26,3,30,39,35c25,4,37,10,31,16s-23,6-44,0
				c-35-10-198,3-228,17c-8,4-26,4-40,0c-15-4-18-7-8-8c11-1,21-15,27-38c14-49,14-58-2-72c-20-16-37,7-37,51c0,38-5,43-34,32
				c-19-7-21-30-3-37c24-9-2-18-51-19c-62,0-91,14-47,23l30,7l-27,1c-16,0-28,6-28,11c0,6-14,19-31,29c-25,15-48,18-140,16
				c-60-1-109,2-109,7c0,13-22,9-34-6c-9-12-37-13-165-6c-85,5-160,6-167,3c-21-8-17-20,8-27c18-4,20-8,11-17c-10-10-17-9-33,6
				c-11,10-20,26-20,35c0,12-11,19-37,24c-57,9-103,7-103-4c0-5-13-10-30-10c-16,0-30,4-30,9c0,13,87,71,105,71c8,0,19,9,25,20
				s16,20,24,20c7,0,23,19,37,43c21,37,24,55,25,153c1,99,4,114,23,133c25,25,26,40,7,66c-8,11-12,25-9,32s0,19-5,26c-6,7-10,33-9,57
				c1,27-3,45-11,48c-15,5-16,34-2,48c8,8,7,29-2,70c-12,53-16,59-41,62c-21,3-27,9-27,27c0,13-4,27-10,30c-5,3-10,1-10-4
				c0-6-7-11-15-11s-15,5-15,11c0,5-4,7-10,4c-5-3-10,3-10,14c0,25-13,27-30,6c-10-12-14-12-26,0c-8,7-14,18-14,24c0,12-31,3-43-13
				c-6-8-10-7-14,2c-2,6-9,9-15,6c-6-4-14-1-18,5c-4,7-19,13-33,15c-15,1-30,6-34,9c-13,13-30,7-41-15l-11-23v25c-1,14-5,20-9,14
				c-11-16-40-24-44-12c-7,21-43-13-66-63c-21-46-30-54-59-50c-7,1-13-6-13-16s-9-26-19-35c-11-10-22-36-26-68c-6-45-4-54,14-71
				c12-11,21-25,21-31c0-7,5-6,15,2c13,10,15,9,15-9c0-11,5-21,10-21c6,0,10-7,10-15s14-17,35-21c19-3,32-10,29-15c-5-9,9-13,35-10
				c7,2,10-2,7-7c-11-19,51-35,85-23c40,14,39,14,33-3c-5-12,34-51,43-43c1,2-2,19-7,37c-8,30-7,37,11,55l21,20l-6-22
				c-5-16-2-23,11-26c14-4,13-5-5-6c-20-1-21-4-12-21c11-21,40-28,40-9c0,7,8,18,18,25c16,13,16,13,0,14c-13,0-18,8-18,30
				c0,17-2,30-5,30c-11,0-61-26-68-35c-4-6-7-1-5,10c3,17-3,20-37,20c-22,0-47,5-55,10s-28,16-45,25c-39,20-84,103-69,128
				c6,9,20,18,32,20c11,2,25,16,32,33c8,18,18,28,26,25c7-3,17,6,23,22c9,25,9,25,10,5c1-23,34-33,45-14c6,10,48,8,105-5
				c23-5,32-4,28,3c-4,6,1,17,10,24c14,12,15,11,9-9c-5-13-3-22,3-22c17,0,44-49,46-83c4-49,16-86,30-95c10-5,11-18,4-57
				c-5-27-7-72-3-100c4-27,6-69,5-92s3-52,9-64c7-12,13-34,15-48c1-14,6-42,10-61c6-29,5-33-6-22c-22,21-29,14-29-32c0-45-10-56-51-56
				c-10,0-19-7-19-15c0-20-10-19-35,5c-20,18-23,19-35,5c-17-21-30-19-30,5c0,11-4,20-10,20c-5,0-10-5-10-11s-9-9-19-6
				c-13,3-21-2-25-16c-4-17-7-18-20-7c-9,7-16,19-16,26c0,17-16,18-26,2c-5-8-10-6-15,8c-7,18-8,18-8,0c-1-14-4-16-14-7
				c-8,6-17,8-20,4c-4-3-7,8-7,26c0,26-4,32-17,29c-10-2-18-11-18-20s-13-31-29-47c-16-17-28-32-25-34c23-15,104-47,119-47
				c21,0,28,16,10,22c-7,2-5,11,4,23c12,16,17,18,27,8s10-13,1-13c-7,0-12-5-12-11s6-9,14-6c9,4,12,0,9-12s3-21,19-26c18-7,10-9-35-12
				c-37-3-55-8-51-15c10-16-17-27-44-17c-17,6-29,6-39-2c-11-10-15-8-20,9c-7,28-67,49-88,32c-8-7-15-19-15-26c0-8-3-14-6-14
				c-8,0-35,28-35,37c0,4-14,7-32,6c-17-1-35,3-38,9c-4,5-37,13-74,16c-55,4-67,2-63-9c3-8,17-13,32-12c41,3,26-20-19-29
				c-45-10-47-9-49,33l-2,32l-97,4c-82,3-97,2-97-12c0-8-7-15-15-15s-15,8-15,19c0,13-9,20-30,23c-16,3-34,1-40-2c-6-4-36,0-66,8
				c-48,14-57,14-65,1c-6-10-9-10-9-1c0,6-4,12-10,12c-5,0-10-7-10-15c0-19-17-19-27-1c-5,9-38,19-88,26c-132,18-239,28-253,22
				c-8-2-37,2-65,11c-82,25-207,37-257,26c-34-8-52-7-80,5c-36,15-80,22-235,36c-49,5-155,16-235,25c-229,24-345,33-374,28
				c-15-3-38,0-51,7s-31,10-39,7c-9-4-51-2-94,4c-42,5-113,12-157,14s-109,7-145,10c-36,4-103,8-150,10s-114,8-150,14
				c-121,19-217,22-341,11c-68-6-144-13-169-14c-46-4-174-20-242-32c-36-5-38-4-38,20c0,41,69,146,97,146c25,0,15,57-14,85
				c-15,14-33,25-40,25c-6,0-21,12-33,27c-17,22-29,27-60,27c-40-1-52-9-103-64c-98-105-83-97-178-97c-47-1-95-6-107-12
				c-24-13-257-53-396-67c-64-7-80,3-69,43c3,14,1,23-7,23c-6,0-31,16-55,35c-61,50-91,57-156,34c-29-10-57-26-62-34c-4-8-18-15-29-15
				c-12,0-29-7-38-15s-34-17-55-20c-22-3-54-11-71-16c-18-5-44-7-58-3c-14,3-32,2-39-4c-6-5-38-10-71-11c-32,0-64-5-71-11
				c-20-17-121-25-129-11c-11,18,5,31,164,128c23,14,26,23,27,74c2,52-1,61-24,79c-14,11-31,20-38,20s-25,12-40,26c-29,27-69,28-98,4
				c-6-6-32-28-57-50s-50-40-57-40c-6,0-19-10-27-21c-10-15-21-20-36-16c-14,4-27-1-38-15c-35-42-44-48-75-49c-18-1-41-3-52-4
				c-24-3-98-34-105-44c-9-13-102-61-117-61c-8,0-26-13-40-30c-13-16-31-30-39-30s-24-9-36-20s-28-20-35-20c-8,0-32-9-55-21
				c-22-11-50-23-62-26c-11-2-31-7-44-10l-23-6l16,39c20,49,61,111,97,147c16,15,28,34,28,42s7,15,15,15s15,4,15,8c0,5,20,23,44,41
				c24,19,46,42,49,51c3,10,20,26,38,35c18,10,50,35,70,56c21,22,41,39,45,39s14,7,23,15c14,13,42,36,149,126c13,10,26,19,28,19
				c6,0,22,13,72,59c20,18,53,45,71,60c19,14,58,47,85,74c28,26,54,47,57,47c8,0,72,55,125,108c23,23,47,42,52,42c6,0,17,6,24,13
				c16,16,148,106,190,129c16,9,37,23,46,30c9,8,35,23,57,34c53,26,105,65,105,80c0,6,7,17,15,24c21,17,19,52-5,73c-11,9-20,23-20,31
				c0,18-78,69-93,61c-36-19-60-34-80-52c-12-11-35-24-52-27c-16-4-40-17-52-28c-16-15-39-22-75-24c-42-2-57-8-73-27
				c-25-30-59-48-99-53c-17-1-57-10-89-18c-32-9-67-16-78-16c-19,0-26-2-87-33c-19-9-42-17-53-17c-10,0-19-4-19-10c0-10-63-30-97-30
				c-14,0-23,6-23,15c0,8,5,15,10,15c6,0,10,6,10,14s29,44,65,80s65,72,65,80s10,16,22,18s28,13,34,25c6,13,18,31,27,41
				c10,10,17,24,17,30s8,17,18,24c19,15,37,41,53,78c11,27,29,48,98,113c84,79,121,122,144,166c12,24,51,77,87,119
				c90,103,112,138,132,207c16,57,16,61,0,75c-10,8-21,23-25,33c-3,9-15,17-25,17s-27,9-37,20c-38,42-88,13-139-80
				c-31-58-125-180-138-180c-4,0-8-7-8-14c0-8-13-35-30-59c-16-25-30-49-30-53c0-12-55-64-67-64c-13,0-54-45-47-52c3-3-15-23-40-44
				s-46-43-46-49s-14-22-31-34c-24-18-28-25-18-32c12-7,11-9-1-9c-8,0-30-16-49-35s-40-35-47-35c-8,0-14-8-14-19c0-10-7-24-16-31
				c-8-7-14-23-12-36c3-24-56-104-78-104c-6,0-17-6-23-14c-7-8-25-18-41-21c-16-4-32-14-35-23s-30-44-60-77s-55-63-55-68
				c0-15-64-77-80-77c-8,0-24-16-34-35s-27-37-37-40c-11-4-19-10-19-15c0-12-77-50-102-50c-26,0-100-21-113-32c-5-4-28-8-51-8
				c-24,0-49-6-57-15c-8-8-17-12-19-9c-3,2-18,0-34-7c-46-17-164-46-223-53c-29-4-60-14-68-22c-8-7-24-14-36-14c-12-1-40-9-62-20
				s-53-20-70-20c-16,0-65-11-108-25s-85-25-93-25s-24-9-36-20c-28-26-92-27-110-2c-17,24-121,45-163,33c-17-4-42-4-57,1
				c-19,7-35,5-56-6c-16-8-49-19-73-22c-24-4-64-18-88-31c-24-12-51-23-60-23s-32-18-51-40s-42-40-51-40c-8,0-26-11-39-25
				s-29-25-35-25c-7,0-23-10-37-22c-13-13-43-36-65-51c-23-16-59-49-80-75c-21-25-55-63-75-85c-21-22-38-48-38-58c0-11-4-19-10-19
				c-5,0-10-8-10-17c-1-29-39-105-49-98c-12,7-7,68,7,105c6,14,22,42,36,63s26,40,26,44c0,3,14,22,30,43c17,21,30,41,30,45s9,19,20,34
				c12,18,19,42,17,69c-1,23-2,42-3,42c-33,0-114,26-131,42c-22,21-24,21-61,4c-26-12-42-27-50-49c-9-25-22-36-59-50
				c-85-33-114-51-145-89c-16-21-34-38-40-38s-31-15-57-33c-26-19-58-37-73-40c-14-4-48-27-75-52c-26-25-51-45-55-45s-5-6-3-12
				c3-7,1-19-4-27c-8-12-15-13-35-3c-22,10-28,8-48-17c-13-15-44-37-70-49c-25-12-49-26-53-32c-3-5-14-10-24-10c-9,0-30-13-46-30
				c-16-16-32-30-37-30c-12,0-142-80-154-96c-6-8-25-17-43-20c-27-6-31-4-31,15c0,11,14,49,30,83c17,34,30,68,30,75c0,8,6,16,14,19
				s17,16,20,30c4,13,11,24,16,24c6,0,10,7,10,15c0,18,65,122,160,256c25,35,56,82,70,105c31,51,58,90,87,129c35,45,36,48,57,88
				c11,20,22,37,27,37c4,0,17,17,29,37c25,43,20,83-10,83c-11,0-29,9-40,20s-27,20-36,20c-40,0-93,24-105,48c-18,33-57,73-94,96
				c-16,11-36,28-43,38s-19,18-27,18s-20,9-27,19c-17,23-115,91-132,91c-7,0-26,11-42,25s-38,25-49,25c-12,0-25,4-30,9
				c-8,7-156,65-217,85c-25,8-22,29,7,64c14,17,25,34,25,39c0,6,20,31,45,57c25,27,45,52,45,56s21,29,46,56s52,63,61,81
				c8,18,29,43,47,57s39,38,45,55c22,52,48,56,290,44c119-6,227-12,241-12c14-1,59-3,100-5c184-11,467-17,730-17c157,0,373,0,480,0
				s200,0,205,1c6,1,96,3,200,6c110,2,191,8,193,14s11,6,23-1c15-7,24-7,33,0c8,7,78,13,162,16c82,3,181,10,219,15c39,5,86,9,105,8
				c19,0,64,9,100,21c61,21,123,33,163,33c9-1,17,3,17,7s18,13,40,20c22,6,52,23,67,38c16,14,38,32,51,40c12,9,22,22,22,30
				c0,9,9,31,21,49c27,44,37,167,19,227c-20,70-28,85-72,145c-55,72-71,87-134,117c-28,13-56,31-63,39s-26,18-43,21c-17,4-32,15-35,24
				c-5,20-98,65-118,57c-8-3-17-1-20,4c-4,6-35,10-70,10s-67,5-70,10c-3,6-16,8-28,5c-13-4-44,4-72,16c-28,13-77,26-110,31
				c-33,4-80,12-105,19c-25,6-57,11-72,11c-16,1-28,5-28,9c0,13-74,11-88-3c-9-9-17-9-34,0c-13,6-68,13-123,16s-118,6-140,8
				c-32,1-42-3-51-19c-9-20-11-20-21-5c-11,16-12,16-12-1c-1-10-6-15-11-12c-6,3-14-4-19-17c-10-25-17-15-23,32c-3,23-6,25-48,22
				c-27-2-50-10-58-20c-11-15-15-14-35,9C14055.7,15296.5,14021.7,15313.5,14021.7,15297.5z M14019.7,15216.5c-2-6-8-10-13-10
				s-11,4-13,10s4,11,13,11S14021.7,15222.5,14019.7,15216.5z M14076.7,15207.5c4-6-5-10-19-10s-26,5-26,10c0,6,9,10,19,10
				C14061.7,15217.5,14073.7,15213.5,14076.7,15207.5z M13806.7,15189.5c-3-3-11,0-18,7c-9,10-8,11,6,5
				C13804.7,15198.5,13809.7,15192.5,13806.7,15189.5z M13596.7,15177.5c0-8-10-16-22-18c-13-2-23,1-23,7s-16,1-35-10
				c-28-17-38-19-50-9c-22,18-8,37,25,32c19-3,30,0,30,7c0,8,14,11,38,9C13584.7,15193.5,13596.7,15187.5,13596.7,15177.5z
				 M13751.7,15187.5c-20-13-40-13-40,0c0,6,12,10,28,10C13760.7,15197.5,13763.7,15195.5,13751.7,15187.5z M14329.7,15169.5
				c2-7-6-12-17-12c-21,0-27,11-14,24C14307.7,15190.5,14324.7,15183.5,14329.7,15169.5z M13221.7,15157.5c8,0,3-6-11-14
				c-37-19-104-19-120,1c-11,13-8,14,23,12c21-2,40,0,45,4c4,5,17,6,28,3S13213.7,15157.5,13221.7,15157.5z M13037.7,15142.5
				c4-8,12-15,20-15s14-4,14-9c0-6-27-10-60-10s-60,4-60,7c0,9,51,40,68,41C13026.7,15157.5,13034.7,15150.5,13037.7,15142.5z
				 M12831.7,15125.5c0-10-9-9-35,2c-17,8-16,8,8,7C12819.7,15133.5,12831.7,15129.5,12831.7,15125.5z M13873.7,15126.5c3-8,14-8,38-2
				c52,14,513,9,518-5c2-8,33-12,85-12c45,0,125-5,177-11s112-12,133-15c21-2,44-7,50-11c6-3,43-12,81-19c39-7,96-20,126-28
				c30-9,61-14,68-11c6,2,12,0,12-5c0-6,13-10,29-10s34-7,41-15s18-15,24-15c11,0,57-23,159-80c53-29,123-89,156-132c7-9,18-44,25-78
				c12-55,11-64-8-100c-11-22-24-40-28-40c-5,0-18-9-30-20c-24-22-35-24-125-20c-49,1-64-1-69-13c-3-9-18-19-34-22s-31-13-34-21
				c-4-12-17-14-48-11c-24,3-47,6-53,8c-5,1-30-3-54-10c-35-10-45-10-52,0c-5,9-14,10-36,2c-52-19-149-21-166-4c-20,20-104,13-125-9
				c-10-11-33-25-51-30c-26-7-36-5-52,11c-21,21-40,24-56,8c-8-8-17-8-31,0c-12,6-72,11-136,11c-96,0-116-3-116-15c0-9-9-15-21-15
				c-15,0-19,5-15,15c8,23-21,18-46-7c-20-20-23-21-26-5c-2,9-8,17-14,17c-5,0-8-6-5-12c5-15-40-48-65-48c-11,0-18,10-20,28
				c-3,27-4,27-78,29c-41,0-67-1-57-4c20-5,23-29,4-40c-8-4-16-16-20-25c-3-10-14-18-24-18c-13,0-14,3-5,12c7,7,12,18,12,24
				c0,16-50,22-73,9c-14-8-20-6-28,8c-6,9-14,15-19,12c-9-6-111-17-145-17c-52,1-119,12-124,20c-3,5-33,12-66,16c-39,4-55,3-45-3
				c11-8,9-12-13-20c-37-14-47-14-53,3c-3,8-32,19-71,26c-61,10-67,10-87-9c-27-25-46-27-46-5c0,14-7,16-40,11c-21-3-40-1-42,3
				c-2,5-34,9-73,10c-106,3-115,2-109-15c6-15-11-22-18-7c-6,15-114,38-168,36c-30-1-64-3-75-3c-58-2-137,10-157,23c-12,9-30,16-40,16
				s-41,11-70,25c-64,31-71,31-100,1c-24-24-53-34-123-43c-47-5-58-11-49-25c5-7,2-8-5-4c-7,5-16,19-21,32c-13,34-34,31-26-3
				c3-16,3-22,0-16c-3,7-23,17-44,23c-22,6-46,17-54,25c-18,18-106,21-106,3c0-6-6-4-13,6c-17,23-205,43-262,27c-26-7-44-7-57,0
				c-10,6-56,12-100,15c-45,2-84,6-86,9c-8,8,8,55,19,55c6,0,51,20,99,44c49,24,103,47,120,51c16,4,30,10,30,15c0,4,14,10,30,14
				c17,3,43,15,59,27s36,19,44,16s25,2,38,11s40,22,59,27c19,6,40,21,46,34c8,15,14,19,19,11c8-13,83-4,99,11c6,5,22,9,37,9
				s34,7,43,15c8,8,21,13,28,10c8-3,28,1,44,10c16,8,41,15,57,15c27,0,198,35,227,46c8,4,29,8,45,9c17,2,53,8,80,13c28,6,91,16,140,23
				c50,6,106,15,125,20s54,9,77,9s54,5,69,10c15,6,59,13,98,15c111,7,178,15,221,27c22,6,55,10,73,10c17-1,32,2,32,6s44,7,97,8
				c265,1,359,5,373,14C13851.7,15140.5,13868.7,15140.5,13873.7,15126.5z M12701.7,15112.5c0-9,7-18,15-21c8-4,15-15,15-25
				c0-14-7-19-28-19c-37,0-39,3-34,45C12673.7,15126.5,12701.7,15144.5,12701.7,15112.5z M12909.7,15100.5c2-9-9-13-37-13
				c-23,0-41,4-41,9C12831.7,15116.5,12902.7,15119.5,12909.7,15100.5z M12583.7,15055.5c-9-9-15-9-24,0s-7,12,12,12
				S12592.7,15064.5,12583.7,15055.5z M12457.7,15036.5c3-6,1-16-5-22c-9-9-14-8-22,3c-8,13-11,13-23-3s-21-17-77-9c-54,8-58,10-24,11
				c22,1,51,8,65,16C12401.7,15049.5,12447.7,15051.5,12457.7,15036.5z M12246.7,14987.5c17-4,22-8,12-9c-10,0-20-7-23-14
				c-2-7-15-14-29-15c-18-1-23,2-19,13c5,12-3,15-32,16l-39,2l35,7C12196.7,14996.5,12209.7,14996.5,12246.7,14987.5z
				 M12111.7,14942.5c-7-8-17-12-22-9s-20-4-34-17l-24-22v26c0,25,29,45,48,33c4-2,14-1,22,4
				C12121.7,14969.5,12127.7,14961.5,12111.7,14942.5z M11929.7,14909.5c2-7-3-12-12-12s-16,7-16,16
				C11901.7,14930.5,11923.7,14927.5,11929.7,14909.5z M11996.7,14916.5c10-15-1-23-20-15c-9,3-13,10-10,16
				C11974.7,14930.5,11988.7,14930.5,11996.7,14916.5z M11711.7,14907.5c0-5-7-10-15-10s-15,5-15,10c0,6,7,10,15,10
				S11711.7,14913.5,11711.7,14907.5z M11239.7,14698.5c10-6,9-10-7-14c-14-3-21,0-21,9
				C11211.7,14709.5,11220.7,14711.5,11239.7,14698.5z M11331.7,14511.5c0-2-7-4-15-4s-15,4-15,10c0,5,7,7,15,4
				C11324.7,14517.5,11331.7,14513.5,11331.7,14511.5z M14596.7,14397.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C14588.7,14407.5,14593.7,14403.5,14596.7,14397.5z M12501.7,14371.5c0-8-5-12-10-9c-6,4-8,11-5,16
				C12495.7,14392.5,12501.7,14389.5,12501.7,14371.5z M11426.7,14356.5c71-10,127-17,400-44c163-17,242-29,251-38s-47-88-108-151
				c-32-32-58-63-58-68c0-13-26-38-39-38c-6,0-11-8-11-17c0-10-22-41-49-69c-27-29-57-64-67-79c-16-24-22-26-53-20c-20,4-50,6-68,6
				c-20-1-39,6-51,19c-11,12-41,24-73,30c-78,13-148,36-205,67c-27,14-70,37-96,51c-27,13-48,29-48,34c0,6-18,28-41,49
				c-35,33-42,47-56,111c-12,59-13,79-3,108c25,72,29,73,175,66C11298.7,14369.5,11388.7,14361.5,11426.7,14356.5z M15431.7,14373.5
				c0-11-19-15-25-6c-3,5,1,10,9,10C15424.7,14377.5,15431.7,14375.5,15431.7,14373.5z M13321.7,14341.5c0-8-4-14-10-14
				c-5,0-10,9-10,21c0,11,5,17,10,14C13317.7,14359.5,13321.7,14349.5,13321.7,14341.5z M12915.7,14342.5c3-8,1-15-4-15
				c-6,0-10,7-10,15s2,15,4,15S12911.7,14350.5,12915.7,14342.5z M13031.7,14336.5c0-5-7-9-15-9c-15,0-20,12-9,23
				C13015.7,14358.5,13031.7,14349.5,13031.7,14336.5z M12436.7,14337.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C12428.7,14347.5,12433.7,14343.5,12436.7,14337.5z M13221.7,14320.5c0-26,0-26-20-8c-16,14-17,20-8,27
				C13215.7,14352.5,13221.7,14348.5,13221.7,14320.5z M14015.7,14321.5c8-22,8-24-8-24c-18,0-29,16-21,30
				C13995.7,14342.5,14008.7,14339.5,14015.7,14321.5z M13271.7,14308.5c0-6-4-13-10-16c-5-3-10,1-10,9c0,9,5,16,10,16
				C13267.7,14317.5,13271.7,14313.5,13271.7,14308.5z M11207.7,13810.5c-10-10-19,5-10,18c6,11,8,11,12,0
				C11211.7,13821.5,11210.7,13813.5,11207.7,13810.5z M11907.7,13546.5c16-13,211-79,237-80c21,0,21-1,2-9c-18-8-19-9-1-9
				c10-1,31-12,48-26c16-14,36-25,43-25s15-3,17-7c2-5,21-17,43-28c42-21,81-55,134-117c40-46,51-125,26-185c-20-48-137-223-181-271
				c-19-21-34-40-34-44c0-5-7-8-15-8s-15-6-15-12c0-14-63-68-79-68c-6,0-11-6-11-14s-7-16-15-20c-8-3-15-14-15-25c0-13-5-18-13-15
				c-7,2-26-7-41-21s-31-25-37-25c-5,0-27-18-49-40s-48-40-60-40c-11,0-20-4-20-10c0-5-6-10-14-10s-27-10-43-23c-15-13-62-40-103-61
				s-80-45-87-53c-6-8-17-13-25-12c-7,1-39-9-70-24s-84-33-119-41c-34-8-67-20-72-25c-6-6-19-11-29-11s-18-6-18-14c0-9-15-16-42-19
				c-69-8-226-45-256-60c-15-8-57-17-94-19c-89-7-100,4-67,67c13,25,27,45,31,45s19,21,34,48c15,26,36,57,47,69c10,12,17,25,14,30
				s10,29,29,52c19,24,34,50,34,57s10,25,23,41c13,15,27,35,30,43c8,17,30,53,37,60c3,3,15,25,28,50c29,58,55,104,63,110
				c3,3,19,28,34,55c16,28,32,52,36,55c3,3,19,25,34,50s44,65,64,90c40,49,65,84,97,137c12,19,31,41,43,49c11,8,21,22,21,30
				c0,16,8,26,51,72c15,15,33,41,42,57c8,17,34,50,56,75s41,51,41,57c0,7,18,31,40,54c39,40,41,41,87,34
				C11875.7,13558.5,11901.7,13551.5,11907.7,13546.5z M12591.7,13367.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C12586.7,13377.5,12591.7,13373.5,12591.7,13367.5z M9989.7,13255.5c-3-7-11-13-18-13s-15,6-17,13c-3,7,4,12,17,12
				S9991.7,13262.5,9989.7,13255.5z M10144.7,13240.5c-7-2-19-2-25,0c-7,3-2,5,12,5S10150.7,13243.5,10144.7,13240.5z
				 M11346.7,13237.5c3-5-1-10-10-10s-13,5-10,10c3,6,8,10,10,10S11343.7,13243.5,11346.7,13237.5z M22451.7,13041.5c0-11-5-10-18,4
				c-11,9-17,21-15,25C22425.7,13081.5,22451.7,13057.5,22451.7,13041.5z M22531.7,13035.5c0-16-3-19-11-11c-6,6-8,16-5,22
				C22526.7,13063.5,22531.7,13059.5,22531.7,13035.5z M15221.7,13032.5c0-21-36-55-58-55c-28,0-141-58-191-98c-54-44-100-68-146-76
				c-19-4-35-10-35-14s-26-20-57-34c-32-15-68-36-80-47c-12-12-35-21-51-21c-15,0-33-5-40-12c-15-15-35,37-27,72c15,65,28,82,88,111
				c33,16,68,29,78,29c9,0,22,6,28,14c6,7,40,21,76,31c36,9,65,21,65,26s13,9,28,9c16,0,32,3,36,8c4,4,17,8,29,9c33,2,155,32,201,48
				C15217.7,13050.5,15221.7,13050.5,15221.7,13032.5z M22581.7,13037.5c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
				C22577.7,13047.5,22581.7,13043.5,22581.7,13037.5z M25021.7,13021.5c0-8-4-12-10-9c-5,3-10,10-10,16c0,5,5,9,10,9
				C25017.7,13037.5,25021.7,13030.5,25021.7,13021.5z M9481.7,13018.5c0-5-7-11-14-14c-10-4-13-1-9,9
				C9464.7,13028.5,9481.7,13032.5,9481.7,13018.5z M22425.7,13012.5c-6-16-24-21-24-7c0,11,11,22,21,22
				C22427.7,13027.5,22428.7,13020.5,22425.7,13012.5z M22651.7,13013.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11
				C22647.7,13017.5,22651.7,13015.5,22651.7,13013.5z M22571.7,12997.5c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
				C22567.7,13007.5,22571.7,13003.5,22571.7,12997.5z M22714.7,12966.5c3-13,1-19-6-16c-15,5-22,37-9,37
				C22704.7,12987.5,22711.7,12978.5,22714.7,12966.5z M24964.7,12898.5c-3-14-10-37-16-51c-15-38-19-53-18-82c1-23-3-28-24-28
				s-26-7-36-46c-8-32-15-42-22-35c-16,16-47,13-53-4c-4-8-12-15-20-15s-14-7-14-16c0-17-41-16-41,1c0,6-9,13-20,17
				c-42,16-7,108,70,182c16,15,27,35,24,42c-3,8,1,14,10,14c8,0,17,7,21,15c3,8,14,15,26,15c11,0,34,13,52,30l33,29l17-20
				C24965.7,12932.5,24969.7,12916.5,24964.7,12898.5z M24725.7,12880.5c6-19,2-24-20-29c-32-8-57,5-63,32c-3,18-3,18,17,1
				c20-18,20-18,15,23l-6,42l25-24C24707.7,12913.5,24721.7,12892.5,24725.7,12880.5z M25991.7,12915.5c0-6-7-5-15,2s-15,17-15,22
				c0,6,7,5,15-2S25991.7,12920.5,25991.7,12915.5z M22391.7,12927.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C22386.7,12937.5,22391.7,12933.5,22391.7,12927.5z M26508.7,12891.5c-3-3-12-4-19-1c-8,3-5,6,6,6
				C26506.7,12897.5,26512.7,12894.5,26508.7,12891.5z M14161.7,12867.5c0-15-52-63-85-80c-17-8-46-27-66-42s-39-28-42-28
				c-4,0-29-13-57-30c-28-16-55-30-60-30c-22,0-8,29,23,49c74,49,96,67,97,79c0,6,9,12,19,12c11,0,21,3,23,8c4,10,120,71,136,72
				C14155.7,12877.5,14161.7,12873.5,14161.7,12867.5z M9038.7,12805.5c-3-8-6-5-6,6c-1,11,2,17,5,13
				C9040.7,12821.5,9041.7,12812.5,9038.7,12805.5z M25036.7,12807.5c-3-5-10-10-16-10c-5,0-9,5-9,10c0,6,7,10,16,10
				C25035.7,12817.5,25039.7,12813.5,25036.7,12807.5z M26431.7,12807.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C26426.7,12817.5,26431.7,12813.5,26431.7,12807.5z M9192.7,12774.5c-13-13-26-3-16,12c3,6,11,8,17,5
				C9199.7,12787.5,9199.7,12781.5,9192.7,12774.5z M26381.7,12745.5c0-10-7-18-15-18c-15,0-21,40-8,53
				C26367.7,12789.5,26381.7,12767.5,26381.7,12745.5z M25976.7,12767.5c3-5-1-10-10-10s-13,5-10,10c3,6,8,10,10,10
				S25973.7,12773.5,25976.7,12767.5z M14335.7,12676.5c3-17,6-48,6-68c0-29-5-38-27-48c-15-7-35-13-45-13s-18-4-18-10c0-5-9-10-20-10
				s-20-4-20-10c0-5-8-10-19-10c-16,0-80-26-121-49c-8-5-28-14-45-21c-16-7-42-19-57-26c-38-20-51-18-36,6c7,11,18,20,24,20
				s19,9,29,20s22,20,26,20c28,0,219,164,219,187c0,8,5,11,11,8c6-4,17,2,25,14s26,21,38,21
				C14323.7,12707.5,14330.7,12700.5,14335.7,12676.5z M24941.7,12672.5c0-8-7-15-15-15s-15,7-15,15s7,15,15,15
				S24941.7,12680.5,24941.7,12672.5z M13835.7,12652.5c3-9,0-15-9-15c-8,0-15,7-15,15s4,15,9,15S13831.7,12660.5,13835.7,12652.5z
				 M22896.7,12627.5c3-5-1-10-10-10s-13,5-10,10c3,6,8,10,10,10S22893.7,12633.5,22896.7,12627.5z M24674.7,12620.5c15-56,17-74,7-68
				c-6,4-18,1-26-6c-17-14-19-12-28,24c-5,20-2,29,9,33c8,4,15,12,15,20C24651.7,12643.5,24669.7,12640.5,24674.7,12620.5z
				 M14901.7,12562.5c0-19-191-182-223-190c-29-8-50,7-61,42c-5,17-1,25,21,37c60,32,136,66,150,66c9,0,13,6,10,14c-4,10-1,13,8,10
				c7-3,26,1,42,10C14876.7,12566.5,14901.7,12571.5,14901.7,12562.5z M24591.7,12391.5c0-8-4-12-10-9c-5,3-10,10-10,16c0,5,5,9,10,9
				C24587.7,12407.5,24591.7,12400.5,24591.7,12391.5z M24601.7,12353.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11
				C24597.7,12357.5,24601.7,12355.5,24601.7,12353.5z M24621.7,12306.5c0-11-4-17-10-14c-5,3-10,13-10,21s5,14,10,14
				C24617.7,12327.5,24621.7,12318.5,24621.7,12306.5z M22781.7,12297.5c0-5-5-10-11-10c-5,0-7,5-4,10c3,6,8,10,11,10
				C22779.7,12307.5,22781.7,12303.5,22781.7,12297.5z M21121.7,12216.5c0-35-15-31-22,6c-4,17-1,25,8,25
				S21121.7,12236.5,21121.7,12216.5z M20591.7,12226.5c0-6-7-9-15-5c-9,3-15,0-15-9c0-10-10-15-27-15l-28,1l24,19
				C20555.7,12239.5,20591.7,12244.5,20591.7,12226.5z M21311.7,12217.5c0-5-4-10-9-10c-6,0-13,5-16,10c-3,6,1,10,9,10
				C21304.7,12227.5,21311.7,12223.5,21311.7,12217.5z M22351.7,12217.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C22346.7,12227.5,22351.7,12223.5,22351.7,12217.5z M21601.7,12162.5c-7-8-17-12-22-9c-6,4-5,10,3,15
				C21604.7,12182.5,21615.7,12178.5,21601.7,12162.5z M21841.7,12162.5c0-8-4-15-10-15c-5,0-10,7-10,15s5,15,10,15
				C21837.7,12177.5,21841.7,12170.5,21841.7,12162.5z M21231.7,12157.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C21226.7,12167.5,21231.7,12163.5,21231.7,12157.5z M24661.7,12127.5c8-5,11-12,8-16c-10-9-38,3-38,16
				C24631.7,12139.5,24641.7,12140.5,24661.7,12127.5z M21711.7,12097.5c0-20-5-30-15-30c-18,0-18,1-5,34
				C21704.7,12135.5,21711.7,12134.5,21711.7,12097.5z M11188.7,12111.5c-3-3-12-4-19-1c-8,3-5,6,6,6
				C11186.7,12117.5,11192.7,12114.5,11188.7,12111.5z M22227.7,12060.5c-2-3-9-2-15,2c-8,4-7,13,1,28l13,22l3-23
				C22231.7,12076.5,22230.7,12063.5,22227.7,12060.5z M21407.7,12070.5c-10-10-19,5-10,18c6,11,8,11,12,0
				C21411.7,12081.5,21410.7,12073.5,21407.7,12070.5z M21833.7,12085.5c7-18-2-39-13-32c-12,7-12,44,0,44
				C21825.7,12097.5,21831.7,12091.5,21833.7,12085.5z M22851.7,12081.5c0-9,8-23,18-30c15-12,14-13-15-14c-30,0-33,3-33,30
				c0,20,5,30,15,30C22844.7,12097.5,22851.7,12090.5,22851.7,12081.5z M26251.7,12081.5c0-2-7-4-15-4s-15,4-15,10c0,5,7,7,15,4
				C26244.7,12087.5,26251.7,12083.5,26251.7,12081.5z M22515.7,12072.5c3-9,0-15-9-15c-8,0-15,7-15,15s4,15,9,15
				S22511.7,12080.5,22515.7,12072.5z M23036.7,12077.5c3-6-4-10-17-10c-18,0-20,2-8,10
				C23030.7,12089.5,23029.7,12089.5,23036.7,12077.5z M23181.7,12073.5c0-3,0-10,0-16c0-5-7-10-15-10c-10,0-15-10-16-27l-1-28l-11,26
				l-12,27l-25-34c-32-43-40-42-40,1c0,28,4,35,20,35c11,0,20,5,20,10c0,6-9,10-20,10s-20,4-20,9s27,8,60,6
				C23154.7,12081.5,23181.7,12077.5,23181.7,12073.5z M23511.7,12075.5c0-5-30-8-67-8c-74,0-112-13-74-25s52-65,17-65
				c-21,0-56,36-56,58c0,12-8,26-17,32c-15,9-14,10,9,6c15-4,29-3,31,1C23361.7,12084.5,23511.7,12085.5,23511.7,12075.5z
				 M22756.7,12029.5c-5-13-8-14-15-3c-5,9-3,22,3,33c10,16,12,16,15,3C22761.7,12053.5,22760.7,12038.5,22756.7,12029.5z
				 M23818.7,12071.5c-3-3-12-4-19-1c-8,3-5,6,6,6C23816.7,12077.5,23822.7,12074.5,23818.7,12071.5z M23876.7,12067.5
				c-3-5-12-10-20-10s-17,5-20,10c-4,6,5,10,20,10S23880.7,12073.5,23876.7,12067.5z M24276.7,12060.5c-3-10-9-30-12-45
				c-6-30-23-28-23,3c0,10-7,19-15,19s-15,5-15,10c0,6,4,10,9,10c6,0,13-4,16-10c11-18,23-11,18,10c-4,14,0,20,12,20
				S24281.7,12072.5,24276.7,12060.5z M22388.7,12061.5c-3-3-12-4-19-1c-8,3-5,6,6,6C22386.7,12067.5,22392.7,12064.5,22388.7,12061.5
				z M24795.7,12058.5c3-5-12-11-34-13c-45-4-55,8-12,15c15,2,30,5,34,6C24787.7,12066.5,24792.7,12063.5,24795.7,12058.5z
				 M26191.7,12057.5c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10C26187.7,12067.5,26191.7,12063.5,26191.7,12057.5z
				 M22661.7,12053.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11C22657.7,12057.5,22661.7,12055.5,22661.7,12053.5z
				 M23909.7,12013.5c-13-20-26-36-29-36c-5,0-29,45-29,55c0,3,16,5,35,5c20,0,35,5,35,12c0,6,3,9,6,6
				C23930.7,12051.5,23922.7,12033.5,23909.7,12013.5z M10860.7,12001.5c-11-14-14-13-22,2c-7,13-5,21,9,31c17,12,19,12,22-2
				C10871.7,12023.5,10867.7,12009.5,10860.7,12001.5z M21698.7,12041.5c-3-3-12-4-19-1c-8,3-5,6,6,6
				C21696.7,12047.5,21702.7,12044.5,21698.7,12041.5z M23011.7,12043.5c0-11-19-15-25-6c-3,5,1,10,9,10
				C23004.7,12047.5,23011.7,12045.5,23011.7,12043.5z M24155.7,12038.5c8-12-3-41-15-41c-5,0-9,11-9,25
				C24131.7,12047.5,24145.7,12056.5,24155.7,12038.5z M22182.7,12014.5c-13-13-26-3-16,12c3,6,11,8,17,5
				C22189.7,12027.5,22189.7,12021.5,22182.7,12014.5z M22236.7,12017.5c0-22-29-18-33,3c-3,14,1,18,15,15
				C22228.7,12033.5,22236.7,12025.5,22236.7,12017.5z M24691.7,12016.5c0-6-4-7-10-4c-5,3-10,11-10,16c0,6,5,7,10,4
				C24687.7,12029.5,24691.7,12021.5,24691.7,12016.5z M25511.7,12030.5c0-10-20-40-20-31c0,3-3,13-6,22c-4,11-1,16,10,16
				C25504.7,12037.5,25511.7,12034.5,25511.7,12030.5z M22716.7,11999.5c-8-8-25,10-19,20c4,6,9,5,15-3
				C22716.7,12009.5,22718.7,12001.5,22716.7,11999.5z M25001.7,12008.5c0-11,5-23,10-26c15-10,12-35-5-35c-8,0-15,8-15,18
				s-7,28-16,40c-14,21-14,22,5,22C24994.7,12027.5,25001.7,12021.5,25001.7,12008.5z M25466.7,12018.5c3-5,1-12-5-16c-5-3-10,1-10,9
				C25451.7,12029.5,25457.7,12032.5,25466.7,12018.5z M22909.7,11994.5c2-10-3-17-12-17c-8,0-20-9-26-20c-13-24-32-17-23,8
				C22864.7,12011.5,22902.7,12029.5,22909.7,11994.5z M23198.7,11941.5c-2-2-14-1-27,4c-20,7-21,10-7,15c10,4,17,14,18,24
				c0,12,4,8,11-10C23198.7,11958.5,23201.7,11944.5,23198.7,11941.5z M24494.7,11949.5c-5-20-7-21-18-6c-7,10-10,26-7,36
				c4,16,7,17,18,6C24495.7,11978.5,24498.7,11962.5,24494.7,11949.5z M23536.7,11962.5c0-10-6-20-12-22c-8-3-13,5-13,22s5,25,13,23
				C23530.7,11982.5,23536.7,11972.5,23536.7,11962.5z M25161.7,11951.5c0-7-9-14-20-17c-23-6-28,21-8,41
				C25146.7,11988.5,25161.7,11975.5,25161.7,11951.5z M22521.7,11962.5c0-8-4-15-10-15c-5,0-10,7-10,15s5,15,10,15
				C22517.7,11977.5,22521.7,11970.5,22521.7,11962.5z M25761.7,11951.5c0-8-10-14-25-14c-23,0-34,18-19,32
				C25727.7,11979.5,25761.7,11965.5,25761.7,11951.5z M22571.7,11957.5c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
				C22566.7,11967.5,22571.7,11963.5,22571.7,11957.5z M23493.7,11952.5c0-5-5-11-11-13s-11,4-11,13s5,15,11,13
				S23493.7,11957.5,23493.7,11952.5z M24786.7,11948.5c-3-6-11-11-17-11s-6,6,2,15C24785.7,11969.5,24797.7,11965.5,24786.7,11948.5z
				 M23924.7,11950.5c-7-2-19-2-25,0c-7,3-2,5,12,5S23930.7,11953.5,23924.7,11950.5z M24051.7,11947.5c0-5-2-10-4-10c-3,0-8,5-11,10
				c-3,6-1,10,4,10C24046.7,11957.5,24051.7,11953.5,24051.7,11947.5z M24199.7,11945.5c-6-18-28-21-28-4c0,9,7,16,16,16
				S24201.7,11952.5,24199.7,11945.5z M26376.7,11947.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C26368.7,11957.5,26373.7,11953.5,26376.7,11947.5z M10141.7,11931.5c0-2-7-4-15-4s-15,4-15,10c0,5,7,7,15,4
				C10134.7,11937.5,10141.7,11933.5,10141.7,11931.5z M25061.7,11925.5c0-19-2-20-10-8c-13,19-13,30,0,30
				C25057.7,11947.5,25061.7,11937.5,25061.7,11925.5z M25878.7,11875.5c-2-13-4-3-4,22s2,35,4,23
				C25880.7,11907.5,25880.7,11887.5,25878.7,11875.5z M25611.7,11877.5c0-5-5-10-11-10c-5,0-7,5-4,10c3,6,8,10,11,10
				C25609.7,11887.5,25611.7,11883.5,25611.7,11877.5z M26001.7,11842.5c7-9,8-15,2-15c-5,0-12,7-16,15c-3,8-4,15-2,15
				S25994.7,11850.5,26001.7,11842.5z"/>
			<path d="M5506.7,15137.5c-58-10-136-48-167-81c-15-16-36-29-47-29s-24-6-28-13c-12-20-83-56-128-65c-26-5-44-16-51-30
				c-13-27-84-79-119-88c-15-4-30-17-35-29c-10-26-30-36-59-28c-23,6-28-6-9-25c9-9,8-12-5-12c-9,0-17,5-17,10c0,6-5,10-11,10
				c-8,0-9-15-5-47c6-47,6-48-11-27c-27,30-74,37-116,15c-20-10-33-22-29-25c3-4-2-4-12,0c-14,6-17,2-14-17c2-19-1-24-19-23
				c-16,0-23-5-23-17s-11-20-32-24c-18-4-43-16-55-28c-13-11-40-23-60-27c-23-4-37-11-35-19c1-7-2-10-7-7c-15,10-41-5-41-24
				c0-10-7-23-15-30c-11-9-15-9-15-1s-7,7-22-3c-12-9-30-16-40-16s-18-6-18-13c0-8-18-20-41-27c-30-10-39-18-36-31c6-22-19-59-39-59
				c-8,0-23-13-32-30c-12-22-24-30-44-30s-28-5-28-18c0-12-14-22-46-31c-29-9-64-31-96-62c-28-27-57-49-65-49c-7,0-18-11-23-25
				s-13-25-17-26c-5,0-17-2-28-4c-10-2-24,0-30,5c-12,10-55-20-115-82c-21-21-45-38-54-38c-10,0-20-9-23-20s-9-18-13-15
				c-9,6-50-50-50-70c0-10-7-15-17-13c-18,2-89-60-79-69c4-5-36-53-75-92c-9-9-18-25-21-36c-2-11-13-21-25-23s-26-15-33-32
				s-16-28-20-25s-22-1-39-8c-24-10-28-16-19-25s9-12-1-12c-15,0-50-40-51-57c0-19-20-26-35-13c-12,10-15,10-15,0c0-7-13-29-30-50
				c-16-21-30-41-30-45c0-13-42-66-48-61c-13,14-32-20-32-58c0-45-13-66-42-66c-41,0-98-45-98-77c0-15-9-27-24-34c-13-6-27-19-30-28
				c-7-19-48-79-71-102c-8-9-15-19-15-23s-5-12-10-19c-23-28-120-166-120-171c0-3-22-39-49-79c-28-41-53-85-56-100c-4-14-18-33-31-42
				c-13-8-24-24-24-35c0-10-7-26-16-34c-8-9-12-16-8-16s-1-9-11-20s-23-20-29-20s-35-27-64-60c-30-33-60-60-68-60c-26,0-36,53-41,225
				c-3,94-9,172-14,173c-14,5-11,23,6,42c9,10,20,51,25,91c5,41,21,117,35,169s31,114,37,138c6,23,15,42,20,42c4,0,8,9,8,20
				c0,20,3,27,34,87c10,19,12,34,7,38c-6,3,3,25,20,49c16,24,29,53,29,64s7,25,15,32s15,19,15,28c0,23,23,82,32,82c4,0,8,7,8,15
				s18,50,40,94c22,43,40,82,40,86c0,7,141,293,165,335c8,14,15,32,15,41s8,27,19,40c10,13,28,51,40,83c12,33,31,72,41,87
				c11,15,20,34,20,42c0,9,6,28,14,44c64,128,146,311,146,325c0,6,6,19,14,27c7,9,32,59,54,111c52,121,56,188,12,214
				c-38,22-83,20-105-4c-10-11-24-20-31-20s-16-9-19-21c-5-14-12-18-23-14c-40,15-73,17-88,4c-9-7-26-18-37-24c-12-6-32-26-45-45
				c-12-19-29-43-37-53c-8-9-15-21-15-25s-19-27-41-52c-23-25-52-63-66-85c-15-22-43-57-64-78s-41-48-45-59c-4-15-12-18-29-14
				c-18,5-29-2-51-32c-15-20-36-45-46-55s-18-26-18-37c0-10-10-27-22-37c-13-10-36-37-51-59c-16-23-48-59-73-79c-24-20-44-43-44-50
				s-24-33-52-56c-29-24-56-48-60-54c-23-34-48-63-88-104c-46-48-65-74-76-103c-8-22-24-13-24,12c0,15-9,10-39-23c-22-23-37-44-35-47
				c3-2,9,2,13,9c5,8,11,9,14,4c4-6-5-15-18-21c-17-8-24-18-22-34c1-14-4-24-13-24c-8-1-17-3-20-4s-12-3-21-5c-23-4-69-53-69-73
				c0-15-2-15-10-2c-8,12-10,11-10-7c0-14-6-23-15-23c-8,0-22-12-32-27c-9-16-30-41-46-56c-23-23-26-31-16-43c9-11,7-14-15-14
				c-24,0-30-8-27-37c2-14-47-67-76-82c-13-6-23-21-23-32c0-18-40-44-65-41c-5,1-27-23-49-53c-24-33-44-51-52-48c-9,3-14-2-14-14
				s-11-25-26-32c-38-17-84-63-84-83c0-10-7-18-15-18s-15-7-15-15s-4-15-10-15c-5,0-10-7-10-16c0-13-3-14-13-5c-11,8-17,5-26-13
				c-7-13-23-29-35-35c-21-12-60-67-61-86c0-5-8-13-17-17c-19-8-25-38-8-38c6,0,10-5,10-11s-9-8-20-4c-23,7-55-19-44-36
				c11-18-18-60-40-58c-37,2-46-1-46-16c0-8-9-15-20-15s-20-4-20-10c0-5-16-26-36-46c-29-28-34-38-23-45s9-9-7-9c-23,0-59-32-95-85
				c-13-19-30-35-36-35c-7,0-16-12-19-27c-7-26-144-208-204-272c-32-33-75-99-84-129c-4-12-19-36-34-54c-33-39-33-99,0-164
				c19-39,25-44,56-44c43,0,89,32,97,69c3,16,17,39,31,52s39,53,55,89c17,36,36,68,44,73c8,4,15,16,15,26s7,24,14,31c8,6,30,35,47,63
				c18,29,51,68,72,88c26,24,37,41,32,49s-4,11,3,6c11-7,79,59,87,86c4,9,15,20,25,23s22,14,25,23s27,40,53,68c91,98,122,133,122,140
				c0,4,26,33,58,64c189,189,262,273,262,300c0,6,9,10,20,10s29,13,41,29c11,16,32,35,45,42s24,18,24,24c0,7,26,39,57,72
				c148,153,315,332,345,368c18,22,37,42,42,44c4,2,16,12,25,23s65,70,124,132c60,61,116,123,125,139c9,15,20,27,24,27s8,6,8,14
				s24,36,54,63c29,27,93,94,141,148c97,108,133,145,146,145c5,0,9,6,9,13c0,6,50,64,110,126c61,63,110,120,110,126c0,5,14,21,30,35
				c17,14,30,30,30,37s7,13,15,13c19,0,20-43,2-59c-8-6-26-43-41-83s-36-91-46-113c-11-22-20-48-20-57s-7-21-16-26c-17-9-42-65-51-114
				c-3-16-9-28-13-28s-13-20-19-45c-6-24-22-65-36-91s-25-52-25-59s-8-23-19-36c-10-13-21-38-25-55c-4-18-16-40-27-50
				c-10-9-19-25-19-36c0-10-4-26-9-36c-5-9-17-42-26-72c-12-37-27-64-49-82c-26-21-36-40-46-87c-7-33-17-59-24-58c-6,1-12-9-14-23
				c-5-37-31-90-48-97c-8-3-14-16-14-28c0-13-5-27-11-30c-5-4-8-12-6-18c2-7,0-23-5-37c-6-14-11-34-13-45s-8-34-13-52c-6-18-8-41-5-52
				s-3-27-16-40c-12-12-21-28-21-37c0-8-12-41-26-72c-15-32-30-88-35-129c-4-39-13-91-19-114c-10-37-12-84-9-289c0-67,5-116,13-128
				c3-6,2-13-4-17c-12-7-1-65,16-86c8-8,14-25,14-36s9-28,19-37c11-10,18-23,15-30s7-25,21-42c15-16,33-38,41-50c11-16,26-21,69-23
				c50-2,73,10,43,22c-8,4-7,6,4,6c25,1,77,34,83,52c3,9,27,41,53,71s58,69,72,87c32,42,82,106,120,153c17,20,30,40,30,45
				c0,4,14,21,30,37c17,16,40,47,52,70c11,22,24,43,27,46c13,10,75,112,87,143c3,9,10,17,15,17s9,4,9,10c0,5,14,29,30,52
				c17,24,43,61,58,82c15,22,39,52,54,67c16,15,28,34,28,43c0,8,11,23,23,33c13,10,26,23,28,29c4,11,65,83,141,168c21,22,38,45,38,49
				c0,5,39,48,88,97c48,48,156,158,240,244s160,156,168,156s14,6,14,14s8,17,19,21c10,3,34,20,52,38c52,49,121,102,155,120
				c27,14,68,47,134,107c9,8,32,24,51,34c31,18,132,91,185,134c12,9,43,29,70,43c40,21,48,30,46,50c-2,23-2,23,11,4c13-17,16-18,31-3
				c9,9,25,20,36,23s20,10,20,16c0,5,15,15,33,24c17,8,39,19,47,24c8,6,47,28,85,50c39,22,85,54,103,70s46,32,62,36c15,3,36,15,46,26
				c9,10,23,19,30,19s19,6,26,13c27,25,74,54,98,62c14,4,33,15,43,23c28,25,83,57,194,112c56,28,103,54,105,58c3,9,170,82,186,82
				c5,0,30,9,56,21c26,11,55,18,65,14c20-6,83,17,97,37c17,22,10,82-11,109C5695.7,15137.5,5601.7,15153.5,5506.7,15137.5z
				 M5020.7,14798.5c0-9-4-25-8-35c-7-18-8-18-14,5c-11,41-8,59,8,53C5014.7,14817.5,5021.7,14807.5,5020.7,14798.5z M5008.7,14731.5
				c-3-3-12-4-19-1c-8,3-5,6,6,6C5006.7,14737.5,5012.7,14734.5,5008.7,14731.5z M4577.7,14530.5c-10-10-19,5-10,18c6,11,8,11,12,0
				C4581.7,14541.5,4580.7,14533.5,4577.7,14530.5z M4458.7,14345.5c-3-8-6-5-6,6c-1,11,2,17,5,13
				C4460.7,14361.5,4461.7,14352.5,4458.7,14345.5z M756.7,12697.5c-3-5-13-10-21-10s-14,5-14,10c0,6,9,10,21,10
				C753.7,12707.5,759.7,12703.5,756.7,12697.5z M476.7,12437.5c3-6-1-13-10-16c-19-8-30,0-20,15
				C454.7,12450.5,468.7,12450.5,476.7,12437.5z M1991.7,12196.5c0-11-4-17-10-14c-5,3-10,13-10,21s5,14,10,14
				C1987.7,12217.5,1991.7,12208.5,1991.7,12196.5z"/>
			<path d="M12331.7,15097.5c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C12336.7,15107.5,12331.7,15103.5,12331.7,15097.5z"/>
			<path d="M6204.7,14660.5c-11-10-30-38-43-63c-12-25-44-70-69-100c-26-30-53-68-61-84s-21-31-30-34c-17-6-150-136-150-147
				c0-3-17-24-37-44c-74-74-163-172-163-179c0-4-18-24-40-46c-22-21-40-44-40-51c0-6-22-30-49-53s-55-55-62-71s-18-27-25-24
				c-18,7-27-16-14-32c10-13,7-16-18-21c-18-4-34-15-41-30c-6-13-16-24-22-24c-13,0-44-34-64-72c-9-16-20-28-26-28c-15,0-48-41-49-60
				c0-23-40-79-40-57c0,9-7,17-15,17s-15-6-15-14s-13-28-30-46c-16-18-30-37-30-43c0-5-13-22-28-36c-38-35-72-85-72-106
				c0-11-5-15-14-12c-16,6-86-67-86-89c0-8-7-26-15-39c-8-14-22-37-31-51c-10-17-19-22-27-16c-7,4-22,9-35,10c-12,0-35,10-51,21
				c-16,12-35,21-43,21c-7,0-31,7-54,14c-22,8-58,17-80,20c-21,2-48,7-59,10c-29,9-146-35-159-59c-6-11-17-24-23-28c-7-4-13-13-13-19
				c0-7-9-21-19-32c-10-12-24-44-30-73c-9-45-8-58,11-100c30-69,82-129,123-143c19-6,35-15,35-19s17-13,38-19c21-7,47-21,58-33
				c19-21,19-21-1-37c-12-10-42-52-68-94c-27-42-55-82-62-88c-21-18-10-90,25-160c5-10,25-16,67-17l60-3l31,44c18,25,32,49,32,55
				s12,25,28,43c15,18,38,56,51,83c12,28,27,58,32,67c5,10,9,22,9,28c0,8,55,87,76,109c10,11,44-42,53-80c5-23,19-55,31-71
				s23-38,25-50c2-11,15-34,28-52c13-17,26-39,30-49s11-15,17-12c5,3,10-2,10-13c0-21,35-57,67-66c11-4,27-13,33-21c7-8,23-15,36-15
				c14,0,33-5,43-12c15-9,25-9,46,0c14,7,39,12,55,12s31,4,34,8c3,5,31,14,63,21s78,22,103,33s59,26,75,32c17,7,50,22,75,33
				c86,40,118,53,126,53c4,0,15,7,25,16c23,21,39,10,39-25c0-15,8-43,17-62c10-19,19-43,20-53c2-11,19-39,39-63c33-40,39-43,83-43
				c26,0,68,6,93,14c26,7,52,10,59,5c7-4,18-4,24,1c5,4,53,30,105,57c52,26,99,54,103,61c4,6,14,12,22,12s18,6,22,13c4,8,20,21,34,31
				c15,9,39,36,53,59c26,41,81,83,90,68c3-4,3-33,0-65c-6-69,12-202,32-233c8-12,14-29,14-38c0-8,11-20,25-27c13-6,22-15,19-19
				c-2-4,12-30,32-56c29-38,46-50,83-60c57-15,85-9,101,23c15,27,30,32,30,9c0-19,52-20,88-1c15,7,52,26,82,41c87,43,114,59,123,73
				c5,7,17,10,27,7c13-4,42,11,94,50c41,30,79,55,83,55c5,0,26,16,46,35s43,35,50,35c6,0,45,25,85,55s78,55,83,55s9,7,9,15
				c0,9,10,19,23,24s29,16,36,25s18,16,23,16c10,0,97,66,145,110c18,17,37,30,43,30c5,0,12,11,16,25c4,18,11,24,23,20c9-2,29,6,45,20
				c16,13,37,27,47,30c11,4,19,11,19,16s11,14,25,19s25,15,25,22c1,13,61,48,84,48c7,0,24,14,37,32s32,40,42,50c9,10,17,24,17,32
				s4,16,10,18c14,4,0,78-15,78c-6,0-20-9-30-20c-12-13-25-18-40-14c-16,4-37-5-73-30c-39-29-59-36-94-36c-32,0-50-6-63-20
				c-10-11-23-20-30-20c-18,0-56-57-49-74c4-11-3-17-23-22c-100-23-166-49-190-74c-15-16-30-27-35-24c-4,3-8-4-8-15c0-16-6-21-29-21
				c-16,0-31-6-34-14c-3-7-21-16-41-20s-50-20-68-36c-18-17-36-30-40-30c-5,0-8-6-8-13c0-6-12-18-27-24c-16-7-39-23-53-36
				c-14-12-47-34-75-49c-27-14-68-42-90-62c-21-20-68-50-102-68c-35-17-69-38-75-45s-23-13-39-13s-29-3-29-8c0-14-60-34-107-35
				c-47-2-47-2-57,38c-6,22-10,103-9,180c1,120,5,153,28,233c28,97,69,197,85,207c5,3,14,23,20,45c6,21,25,54,43,73c45,48,67,76,67,85
				c0,19,211,240,260,272c13,8,53,45,90,81c37,37,82,75,99,85s33,25,36,34s18,23,34,33c17,10,33,26,36,37c3,10,21,28,39,40
				c19,11,41,31,50,45c9,13,32,32,51,41c20,9,44,28,53,41c37,52,95,113,108,113c8,0,14,5,14,12s20,30,44,53c24,22,46,49,50,60
				c3,11,21,27,39,36c17,8,36,25,42,37c5,12,15,22,22,22s16,6,20,14c7,13,125,143,226,249c30,32,37,46,32,65c-10,39-47,82-71,82
				c-12,0-27,6-33,14c-22,26-60,28-99,4c-20-12-41-27-47-33c-5-6-22-19-37-28c-15-10-35-31-43-47c-9-17-21-30-29-30c-7,0-21-9-31-20
				s-23-20-29-21c-6,0-40-31-76-70c-36-38-91-96-123-130c-32-33-55-64-52-69s-24-28-60-51c-36-24-67-50-70-59c-3-8-28-39-55-68
				c-27-30-52-64-56-75c-4-12-9-24-13-27c-3-3-24-27-46-55c-69-86-78-95-98-95c-10,0-21-9-24-20s-13-20-22-20c-10,0-22-14-30-35
				c-7-19-17-35-22-35c-6,0-10-8-9-17c4-37-12-62-44-68c-57-12-64-16-70-40c-4-17-20-31-46-42c-22-10-40-21-40-25c0-5-9-8-20-8
				s-20-4-20-10c0-17-74-48-262-111c-194-65-258-74-302-45c-25,16-44,7-31-14c4-6-5-8-22-4c-24,5-42-5-118-65c-49-39-92-71-97-71
				c-4,0-8-9-8-20c0-13-7-20-20-20c-11,0-34-16-52-36s-38-42-44-48c-6-7-18-25-27-41c-9-17-30-45-47-64c-49-53-81-108-73-127
				c5-14,3-16-11-11c-12,5-26,0-41-15c-33-33-53-48-63-48c-5,0-18-11-29-24c-15-17-28-23-43-19c-12,3-44-4-73-17c-29-12-61-26-72-31
				c-11-4-42-17-69-28c-27-12-55-21-64-21c-8,0-30-11-48-25c-35-26-64-33-64-13c0,6-7,4-18-5c-43-39-120-8-143,58c-7,19-16,35-20,35
				c-7,0-19,23-64,122c-9,21-18,52-18,69c-2,36,1,38,141,110c46,24,92,52,101,64c9,11,35,30,56,41c22,12,41,28,41,35c1,8,2,20,3,28
				c1,11,4,11,16,1s21-10,40-2c14,7,25,16,25,21s19,19,43,31c23,12,52,33,65,46s26,24,30,24s28,19,53,43c25,23,71,65,102,93
				c30,29,59,53,65,55c17,7,140,177,167,233c15,30,29,74,32,97c5,36,3,44-18,60c-13,10-32,19-42,19s-20,6-23,14c-10,26-74,28-136,6
				c-32-11-62-20-68-20c-17,0-40-27-61-68c-23-44-24-61-4-90c14-19,14-25,1-49c-8-16-27-41-42-58c-14-16-38-46-53-65
				c-14-19-29-37-32-40c-4-3-19-14-34-25c-69-49-142-110-170-139c-33-34-105-85-164-116c-21-10-54-35-74-55c-35-35-67-42-67-15
				c0,14,66,103,113,152c9,10,17,22,17,26s16,23,35,42s35,42,35,52s7,18,16,18c16,0,64,44,64,58c0,4,41,50,90,102s90,101,90,108
				c0,6,5,12,10,12c13,0,140,128,140,141c0,5,7,9,15,9s15,8,15,18s25,39,55,66c30,26,55,52,55,57c0,9,84,102,158,172
				c23,23,42,47,42,54s4,13,9,13s27,19,48,42c41,44,168,176,285,296c56,57,74,82,83,120c8,32,9,53,2,67c-6,11-13,27-17,35
				s-20,19-36,24c-16,4-37,12-46,17C6261.7,14682.5,6226.7,14679.5,6204.7,14660.5z M6768.7,13001.5c5-15-28-27-40-15c-5,5-6,14-2,21
				C6734.7,13020.5,6763.7,13016.5,6768.7,13001.5z M4563.7,12894.5c53-19,102-69,93-97c-4-12-9-19-12-16s-21-1-40-9s-39-13-43-10
				c-5,3-11,1-15-5s-16-3-33,7c-38,25-47,35-66,72c-21,39-10,58,37,64c18,2,34,4,37,5S4542.7,12901.5,4563.7,12894.5z M6514.7,12875.5
				c13-20-7-93-35-124c-10-11-18-27-18-36s-7-21-15-28c-14-12-35-52-49-92c-3-10-12-18-20-18s-26-10-39-22c-31-31-76-68-81-68
				c-3,0-18-12-34-27c-45-42-52-46-120-76c-78-33-82-33-82-2c0,14-5,25-11,25c-8,0-8,5,1,15c7,8,10,26,8,39c-6,28,47,100,107,145
				c18,14,38,36,44,48s28,34,51,48c22,14,40,30,40,35c0,6,7,10,16,10s21,14,27,30c6,17,16,30,22,30s22,10,35,23
				C6432.7,12895.5,6491.7,12913.5,6514.7,12875.5z"/>
			<path d="M3976.7,14237.5c-3-5,1-10,9-10c9,0,16,5,16,10c0,6-4,10-9,10C3986.7,14247.5,3979.7,14243.5,3976.7,14237.5z"/>
			<path d="M4069.7,13821.5c-26-14-28-19-28-79c0-61,9-92,37-122c6-7,14-13,18-13c3,0,18-13,32-30c20-25,32-30,68-30c24,0,47,5,50,10
				c3,6,14,10,23,10c9,1,32,21,50,45c31,43,31,45,12,55c-21,11-50,70-50,101c0,14-7,19-28,19c-15,0-33,5-39,11s-26,14-44,18
				c-19,4-43,11-54,14S4084.7,13829.5,4069.7,13821.5z"/>
			<path d="M21041.7,13467.5c10-34-1-46-29-31c-14,8-22,7-31-4c-14-17-30-20-30-5c0,6,10,14,22,19c21,8,21,8-6,15c-15,4-30,4-35,2
				c-4-3-9-92-10-198c-5-314-8-374-20-396c-12-23-14-104-5-175c5-33,12-48,25-52c11-4,19-15,19-26c0-30,37-61,63-53c12,4,26,12,30,18
				c6,7,7,5,4-6c-6-17,18-32,34-22c6,3,26-2,45-11c64-30,194,9,194,60c0,20-4,22-52,21c-29-1-61,2-71,7c-9,4-34,9-54,11
				c-22,2-43,11-54,24c-9,12-24,22-33,22c-8,0-18,5-21,10c-3,6-1,10,5,10s10,26,8,70c-1,52-6,75-20,90c-17,18-17,19,4,34l22,16l-22,16
				c-12,8-22,22-22,30c0,16,16,19,25,4c13-21,34-9,40,23c4,20,15,37,28,43c12,5,21,15,20,22c-4,17,12,15,27-3c10-12,10-15-4-15
				c-21,0-22-53-1-70c9-7,13-21,10-31c-5-14-2-17,9-13c9,4,23,1,31-6c13-11,21-8,45,16c16,16,30,35,30,43c0,7,6,11,15,8
				c19-8,29,10,27,47c-2,16,3,33,10,37s10,11,6,14c-3,4-16,0-27-7c-17-10-23-10-29-1c-5,8-28,12-57,11c-28,0-65,0-84,1
				c-32,1-33,2-27,35c4,22,2,37-7,44c-9,8-14,36-14,83c-1,57,3,75,18,90c26,26,34,72,19,109c-12,28-14,29-30,15c-19-17-30-11-30,17
				c0,10-4,18-9,18C21036.7,13497.5,21036.7,13485.5,21041.7,13467.5z M21106.7,13398.5c-3-6-11-11-17-11s-6,6,2,15
				C21105.7,13419.5,21117.7,13415.5,21106.7,13398.5z M20981.7,13393.5c0-15-29-30-39-20c-3,3,3,12,13,20
				C20979.7,13410.5,20981.7,13410.5,20981.7,13393.5z M21061.7,13372.5c-9-11-9-15-1-15c19,0,1-53-30-84c-26-27-28-28-26-8
				c0,12,3,19,6,17c7-8,41,27,41,41c0,7-6,11-14,8c-16-6-52,45-39,56c11,11,67,17,71,7
				C21071.7,13390.5,21067.7,13380.5,21061.7,13372.5z M20991.7,13343.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11
				C20987.7,13347.5,20991.7,13345.5,20991.7,13343.5z M21084.7,13153.5c6-15-22-46-40-46c-12,0-25,39-17,52
				C21036.7,13172.5,21078.7,13168.5,21084.7,13153.5z M20996.7,13097.5c3-5,2-10-4-10c-5,0-13,5-16,10c-3,6-2,10,4,10
				C20985.7,13107.5,20993.7,13103.5,20996.7,13097.5z M21087.7,13058.5c-13-20-26-11-26,16c0,25,1,26,16,11
				C21086.7,13076.5,21090.7,13064.5,21087.7,13058.5z M21036.7,13047.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C21028.7,13057.5,21033.7,13053.5,21036.7,13047.5z M21241.7,13013.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11
				C21237.7,13017.5,21241.7,13015.5,21241.7,13013.5z M20957.7,12790.5c-11-11-19,6-11,24c8,17,8,17,12,0
				C20961.7,12804.5,20960.7,12793.5,20957.7,12790.5z M21021.7,12791.5c0-8-5-12-10-9c-6,4-8,11-5,16
				C21015.7,12812.5,21021.7,12809.5,21021.7,12791.5z M20991.7,12657.5c0-11-4-20-10-20c-5,0-10,9-10,20s5,20,10,20
				C20987.7,12677.5,20991.7,12668.5,20991.7,12657.5z"/>
			<path d="M19402.7,13473.5c-9-10-22-14-37-10c-22,6-24,3-24-32c0-22,7-68,15-103c17-72,22-316,13-649c-2-57-1-105,2-107
				c11-7,42,24,35,35c-4,7,4,4,19-5c14-9,33-14,42-11c11,4,15,2,11-8c-12-30,7-23,39,15c23,28,35,54,39,87c11,88,14,95,36,89
				c19-5,20-2,15,28c-7,38,11,59,26,31c9-16,11-16,29,0c10,9,19,28,19,41c0,31,29,42,46,18c7-10,11-21,9-25c-3-4,2-13,10-20
				c10-9,12-17,5-30c-10-19-2-90,11-90c4,0,11,6,15,12c5,8,13-7,20-37c6-28,12-42,13-32c2,38,21,14,21-29c0-42,2-46,36-60
				c74-31,89-30,113,12c30,50,25,100-12,128c-22,16-31,18-46,9c-16-10-20-8-30,13c-16,35-14,37,16,31l27-5l-32,26c-36,30-40,42-15,42
				c15,0,15,1-1,13c-17,12-17,15-4,28c14,12,14,15,0,25c-12,8-12,15-3,32c6,12,8,22,3,22s-14,11-20,25s-18,25-27,25c-8,0-15,5-15,11
				s9,8,20,4c23-7,24-2,9,28c-32,61-91,89-103,49c-9-28-58-70-63-54c-6,15-23,16-23,1c0-18-60-89-89-103c-14-8-37-33-51-55
				c-14-23-28-41-31-41c-4,0-9,14-11,30c-3,21,1,35,14,47c22,20,23,46,2,79c-15,23-15,26,0,43c11,13,14,21,7,26c-18,10-13,36,11,55
				l22,17l-33,33c-20,21-28,35-21,40c6,4,8,14,5,22c-4,9,1,32,9,53c20,47,21,45-9,45c-29,0-31,7-10,30c19,21,33,86,17,76
				c-8-5-12,0-12,13c0,12-6,21-12,21c-7,0-22,3-34,6S19409.7,13482.5,19402.7,13473.5z M19751.7,13037.5c0-13-28-25-38-16
				c-3,4,0,11,8,16C19741.7,13050.5,19751.7,13049.5,19751.7,13037.5z M19541.7,12698.5c-12-44-20-50-40-26c-9,10-9,15-1,15
				c6,0,11,5,11,12c0,13,31,50,37,44C19550.7,12741.5,19547.7,12721.5,19541.7,12698.5z M19483.7,12695.5c-6-6-15-7-22-3
				c-8,5-6,11,7,20C19488.7,12727.5,19500.7,12712.5,19483.7,12695.5z M19958.7,12665.5c-3-8-6-5-6,6c-1,11,2,17,5,13
				C19960.7,12681.5,19961.7,12672.5,19958.7,12665.5z"/>
			<path d="M20981.7,13481.5c0-10,27-33,33-28c9,10-4,34-19,34C20987.7,13487.5,20981.7,13484.5,20981.7,13481.5z"/>
			<path d="M18927.7,13451.5c-4-11-12-13-28-8c-32,10-61,12-73,5c-5-3-20-6-32-7c-13,0-31-10-40-21c-11-12-37-23-66-27
				c-39-6-50-13-62-37c-9-16-23-29-33-29c-38,0-47-17-48-90c-1-38,2-70,6-70s7-8,6-17c-1-13,8-19,29-21c16-2,32-8,35-13
				c9-13,70,33,70,53c0,12-5,15-16,11c-13-5-14,1-10,37c3,24,0,52-6,63c-9,17-7,24,12,39c12,10,32,18,44,18s31,7,43,15s24,11,27,6
				s55-9,116-9c85,1,115-3,134-16c33-21,53-22,36-1c-15,18-1,20,35,3c14-6,25-16,25-22s7-2,15,8c14,19,15,19,15-7c0-15,4-27,9-27
				c4,0,8-15,7-32c-1-23-10-40-28-56c-16-13-28-30-28-39c0-10-6-12-21-8c-13,5-33-2-57-18c-21-13-48-28-62-32s-49-21-78-37
				c-30-16-56-28-58-25c-3,3-6,49-7,103l-2,99l-27,3c-22,3-28-1-28-17c0-11-6-24-12-28c-20-13-49-74-41-86c13-21,11-52-5-70
				c-9-9-16-27-17-40c-1-12-5-59-10-104c-8-90,9-188,31-179c7,3,11-2,10-9c-3-18-16-32-16-17c0,7-4,12-10,12s-10-31-10-73
				c0-68,2-75,24-85c17-7,27-7,34,0c8,8,13,4,17-13c8-34,45-37,45-4c0,17,4,23,14,19c20-8,28,39,12,71c-10,20-12,35-5,52
				c12,32,12,73-1,73c-5,0-10-6-10-12c-1-7-10-2-21,12c-23,27-22,64,2,133c10,25,20,37,33,37c18,0,18,1,1,20c-14,16-15,20-4,21
				c8,1,26,3,42,5c22,2,27-1,28-19c1-17,2-18,6-4c3,11,14,17,34,17c30,0,36,9,18,27c-8,8-7,13,2,19c7,4,14,3,17-4c2-7,8-12,14-12
				c5,0,8,6,6,12c-4,11,14,38,25,38c2,0,3-12,1-27l-4-28l12,27c11,26,57,53,88,51c8-1,18,4,23,10c6,9,10,9,14,0c7-20,21-15,29,10
				c3,12,12,29,19,37s15,26,17,40s10,25,18,25c18,0,40,39,40,70c0,13-6,26-12,29c-8,4-9,8-3,11s9,25,7,50c-4,44-6,46-57,70
				c-29,14-57,25-62,24c-4-1-15,0-23,4c-54,21-93,27-127,21c-49-9-56-13-43-29c7-9,6-16-4-24c-12-10-16-8-22,11c-4,13-7,29-6,36
				C18943.7,13472.5,18934.7,13470.5,18927.7,13451.5z M18996.7,13397.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C18988.7,13407.5,18993.7,13403.5,18996.7,13397.5z M19247.7,13360.5c-11-11-17,4-9,23c7,18,8,18,11,1
				C19251.7,13374.5,19250.7,13363.5,19247.7,13360.5z M19219.7,13265.5c-22-13-28-9-28,19c0,26,0,26,20,8
				C19227.7,13278.5,19228.7,13272.5,19219.7,13265.5z M18571.7,13267.5c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
				C18567.7,13277.5,18571.7,13273.5,18571.7,13267.5z M18568.7,13205.5c-3-8-6-5-6,6c-1,11,2,17,5,13
				C18570.7,13221.5,18571.7,13212.5,18568.7,13205.5z M18645.7,13212.5c-4-8-11-12-16-9c-6,4-5,10,3,15
				C18651.7,13230.5,18651.7,13229.5,18645.7,13212.5z M19205.7,13191.5c26-10,18-24-13-24c-16,0-31,6-35,15
				C19151.7,13198.5,19175.7,13202.5,19205.7,13191.5z M18631.7,13151.5c0-8-4-12-10-9c-5,3-10,10-10,16c0,5,5,9,10,9
				C18627.7,13167.5,18631.7,13160.5,18631.7,13151.5z M18951.7,12977.5c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
				C18947.7,12987.5,18951.7,12983.5,18951.7,12977.5z"/>
			<path d="M25255.7,13402.5c-4-10-14-13-30-9c-21,6-24,3-24-24c0-17,7-64,15-104c11-52,15-125,15-255c-1-100,0-186,2-190
				c3-4,3-26,1-48c-7-91-7-267,0-272c4-2,7,1,7,6c0,6,7,11,16,11c11,0,14,6,9,21l-7,21l21-21c18-18,25-19,42-10c19,9,20,8,14-10
				c-9-28,1-26,32,7c32,34,53,82,53,122c0,43,16,71,37,63c13-5,15-2,9,21c-4,17-2,34,5,42c12,15,33,9,24-7c-4-5,0-9,8-9
				c21,0,50,37,43,55c-9,24,24,32,40,10c7-10,11-21,9-25c-3-4,2-13,10-20c10-8,12-17,5-29c-8-16-6-40,6-80c3-10,8-11,18-2
				c11,9,15,3,21-36c5-27,10-40,12-30c8,34,23,16,23-29c0-42,2-46,36-60c74-31,89-30,113,12c30,50,25,100-12,128c-23,17-31,18-47,9
				c-16-11-20-8-29,19c-10,29-10,30,14,26c34-7,31,7-5,29c-33,19-39,33-14,33c13,0,14,3,5,14c-7,8-11,20-10,28c1,7-1,21-6,31
				c-4,10-2,23,5,32c7,8,8,15,3,15s-15,11-21,25s-18,25-27,25c-8,0-15,5-15,11s9,8,20,4c23-7,24-2,9,28c-19,37-52,67-72,67
				c-11,0-36-18-55-41c-25-29-37-37-43-28c-4,8-9,11-11,9c-2-3-16-24-31-47s-40-49-57-58c-16-8-41-35-56-60c-14-25-29-45-33-45
				c-14,0-11,67,4,80c19,16,19,49,0,74s-19,31,0,50c13,13,14,19,3,36c-11,18-10,23,11,39l24,18l-22,32c-12,17-27,31-33,31
				c-7,0-7,5,1,14c6,7,9,20,6,29c-4,8,1,31,9,52c20,47,21,45-9,45h-25l20,37c20,39,27,79,11,69c-5-3-11,3-14,14c-4,15-14,20-41,20
				C25274.7,13417.5,25258.7,13412.5,25255.7,13402.5z M25598.7,12960.5c-9-9-19-14-23-11c-10,10,6,28,24,28
				C25614.7,12977.5,25614.7,12976.5,25598.7,12960.5z M25356.7,12887.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C25348.7,12897.5,25353.7,12893.5,25356.7,12887.5z M25405.7,12642.5c-7-38-28-55-46-37c-7,7-2,19,16,39c14,15,26,32,26,37s3,7,6,4
				S25409.7,12662.5,25405.7,12642.5z M25346.7,12638.5c-3-6-14-11-23-11c-15,1-15,2,2,15
				C25346.7,12658.5,25357.7,12655.5,25346.7,12638.5z M25818.7,12595.5c-3-8-6-5-6,6c-1,11,2,17,5,13
				C25820.7,12611.5,25821.7,12602.5,25818.7,12595.5z"/>
			<path d="M3911.7,13195.5c-16-29-30-58-30-64s-27-42-60-80s-60-74-60-80c0-5-13-23-30-39c-16-16-30-33-30-38c0-6-13-23-30-39
				c-16-15-30-33-30-39s-21-32-46-58c-26-25-53-60-60-78c-8-18-27-42-44-55c-16-12-32-30-36-40c-3-10-10-16-15-13c-5,4-12-1-16-10
				c-3-10-15-27-27-39s-34-39-51-59c-16-21-56-66-88-100l-59-62l7-48c5-41,12-60,41-105c1-2,17-5,35-8c48-6,81,11,127,64
				c97,113,195,223,239,268c17,18,34,41,37,51s19,32,36,50c16,18,30,34,30,37c0,2,12,18,28,34c15,16,47,56,72,88s78,97,118,145
				c39,48,72,94,72,102s8,25,18,38c9,13,31,52,47,87l29,62l-32,30c-28,25-105,50-154,50C3945.7,13247.5,3928.7,13223.5,3911.7,13195.5
				z"/>
			<path d="M20346.7,13127.5c-4-6-14-10-23-9c-31,4-77-13-100-37c-13-13-32-24-43-24c-14,0-19-5-16-14c3-8,3-16-1-18
				c-13-6-54-70-62-98c-5-16-4-32,2-38s6-12-1-16c-6-4-11-24-11-45s-5-53-11-70c-9-24-9-35,0-44c6-6,11-21,11-34s5-23,10-23
				c6,0,10,5,10,10c0,6,7,10,15,10s15-7,15-15s7-15,15-15s15,8,15,17c0,15,1,15,18,0c9-9,27-25,40-37c12-11,20-25,17-30
				c-4-6,9-10,29-10c19,0,38-4,41-10c3-5,24-10,45-10c26,0,42-6,49-17c8-16,11-15,29,7c17,21,18,26,6,33s-13,12-3,23c12,15,54,20,45,5
				c-4-5-2-20,3-33l10-23l1,25v25l19-25c16-20,21-22,35-11c13,11,19,11,32,0c14-11,18-10,30,6c8,10,14,22,14,27c0,16,20,7,21-9
				c0-15,2-15,9,2c5,11,9,16,9,12c1-4,10-3,22,3c16,9,19,17,14,41c-5,21-3,29,7,29s9,3-2,11c-13,9-13,10,0,6c8-2,14-12,12-22
				s1-14,7-10c6,3,11,17,11,31c0,19,5,24,28,25c15,0,21,3,15,6c-22,8-14,33,12,43c16,6,23,14,19,25c-4,8,0,22,7,31c13,16,18,108,7,120
				c-4,3-22-8-40-25s-35-31-38-31s-5,26-5,57v58l-50,25c-27,14-54,31-58,38c-5,7-13,9-19,6c-6-4-14,0-19,7c-6,11-11,11-20,2
				c-10-10-17-9-32,5c-18,16-19,16-16-3c3-12-2-21-13-23s-18,3-18,12s-4,16-10,16c-5,0-10-4-10-10c0-5-4-10-10-10c-5,0-10,9-10,20
				c0,24-13,26-30,5c-8-9-10-28-5-54c5-32,10-38,26-34c14,4,18,11,14,24s-1,19,9,19c11,0,16-9,16-30c0-33,15-44,72-53
				c41-6,108-54,108-77c0-13,1-13,10,0s11,13,20,0c13-20,13-40-1-40c-5,0-7,5-4,10c3,6,1,10-5,10c-20,0-29-44-13-62c11-13,13-24,6-45
				l-10-28l-12,23c-14,27-31,29-31,3c0-14-5-18-19-14c-15,4-21-2-26-27c-4-17-12-29-17-28c-6,2-22-7-35-20c-23-23-30-24-94-18
				c-84,8-180,44-195,74c-6,12-18,30-27,40c-20,22-22,46-4,60c7,6,12,24,11,39c-1,22,12,40,59,86c33,31,68,57,77,57s25,9,34,19
				c15,16,16,25,6,60c-8,30-16,41-30,41C20361.7,13137.5,20349.7,13132.5,20346.7,13127.5z M20171.7,12968.5c-12-23-30-28-30-9
				c0,16,34,52,38,39C20181.7,12993.5,20177.7,12979.5,20171.7,12968.5z M20391.7,12595.5c0-16-3-19-11-11c-6,6-8,16-5,22
				C20386.7,12623.5,20391.7,12619.5,20391.7,12595.5z"/>
			<path d="M21631.7,13117.5c0-5-13-10-29-10c-17,0-33-4-36-10c-3-5-14-10-24-10c-9,0-27-11-39-25s-31-25-43-25s-18-4-13-11
				c3-6,3-14-2-18c-33-23-65-77-63-105c1-17-1-38-4-46c-6-15-18-112-18-140c0-31,14-80,22-80c5,0,9,5,9,10c0,6,7,10,15,10s15-7,15-15
				s7-15,16-15c10,0,14,6,10,15c-7,18,10,20,21,2c4-7,20-20,36-30c15-10,27-23,27-31c0-13,60-26,121-26c16,0,32-7,38-17
				c8-16,11-15,29,7c16,20,18,26,6,28c-17,4-18,14-2,30c14,14,52,16,44,3c-4-5-2-20,3-33l10-23l1,25v25l19-25c16-20,21-22,35-11
				c13,11,19,11,32,0c14-11,18-10,30,6c8,10,14,22,14,27c0,16,20,7,21-9c0-15,2-15,7-3c4,8,13,15,20,16c33,3,34,5,28,35
				c-5,27-3,30,13,24c16-5,20,0,23,22c2,22,8,28,26,26c12-2,22,2,22,7c0,6-4,10-10,10c-20,0-9,21,16,30c18,7,24,15,20,26
				c-4,9-2,22,2,28c15,19,22,62,16,95c-5,25-10,30-25,26c-13-4-19-1-19,9c0,9-4,16-10,16c-12,0-13-10-1-29c7-10,4-19-7-28
				c-26-19-27-17-24,32c5,54-5,68-70,101c-26,13-48,29-48,35c0,5-5,7-11,3c-6-3-15-1-19,7c-7,10-11,10-25-1c-13-11-18-10-31,6
				c-14,19-14,19-12-1c2-12-4-21-14-23c-11-2-18,3-18,12s-4,16-10,16c-5,0-10-5-10-11s-6-9-12-6c-7,2-12,12-10,21c3,15,0,15-17,4
				c-23-14-24-20-15-66c5-22,11-30,26-29s18,7,13,24c-4,16-1,23,10,23c10,0,15-10,15-34c0-37,3-39,70-50c21-4,52-22,79-46
				c24-22,47-38,51-35s11-2,14-11c9-23,8-27-9-20c-22,8-30-20-15-48c7-14,9-33,5-46l-7-23l-19,24l-19,24l-6-23c-4-15-12-21-21-18
				c-10,4-19-4-26-24c-6-16-15-30-20-30c-4,0-21-10-37-21c-25-19-37-21-97-16c-91,9-175,38-189,65c-6,12-18,30-27,40
				c-19,21-22,53-7,68c5,5,11,25,13,43s11,37,19,42c32,18,88,73,88,87c0,10,7,13,22,9c23-6,58,16,58,37c0,6-5,28-11,49
				c-7,26-15,37-29,37C21640.7,13127.5,21631.7,13123.5,21631.7,13117.5z M21611.7,13077.5c0-5-2-10-4-10c-3,0-8,5-11,10
				c-3,6-1,10,4,10C21606.7,13087.5,21611.7,13083.5,21611.7,13077.5z M21736.7,13027.5c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10
				C21728.7,13037.5,21733.7,13033.5,21736.7,13027.5z M21461.7,12974.5c0-8-9-22-20-32c-19-18-20-17-20,1c0,20,17,44,32,44
				C21457.7,12987.5,21461.7,12981.5,21461.7,12974.5z M21667.7,12570.5c-11-11-19,6-11,24c8,17,8,17,12,0
				C21671.7,12584.5,21670.7,12573.5,21667.7,12570.5z"/>
			<path d="M23394.7,13113.5c-13-7-32-21-43-31c-46-42-138-51-147-14c-3,10-10,16-16,12c-5-3-16,2-24,13c-9,13-16,15-21,8
				c-10-16-52-28-52-15c0,6-4,11-10,11c-5,0-7-7-3-16c5-13,2-15-13-10c-17,5-19,1-19-42c0-55,20-88,58-97c33-9,55-71,37-105
				c-6-12-8-30-5-40c4-11,6-35,7-52c0-18,4-48,10-66c7-23,6-37-1-46c-12-14-4-114,11-133c5-7,7-17,3-23c-9-15,15-11,55,8
				c26,13,34,24,33,40c-2,13,3,22,11,22c21,0,25,22,14,75c-7,34-7,54,1,72c9,19,9,34,0,61c-6,19-9,54-5,78c3,28,1,44-6,46
				c-7,3-9,12-5,22c5,11,13,16,23,12c8-3,13-2,10,3c-8,13,21,34,38,28c8-3,18,4,25,19c9,20,18,24,51,24c27,0,40-4,39-12
				c-3-28,2-38,20-38c10,0,21-7,25-15c13-34,64-1,58,37c-3,16,0,19,18,13c19-6,21-3,18,22c-2,15-7,26-11,23s-15,10-24,29
				c-10,19-25,36-35,39c-10,2-18,8-18,13c0,11-54,39-72,38C23422.7,13125.5,23406.7,13119.5,23394.7,13113.5z M23161.7,13027.5
				c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10C23157.7,13037.5,23161.7,13033.5,23161.7,13027.5z M23177.7,12500.5
				c-10-10-19,5-10,18c6,11,8,11,12,0C23181.7,12511.5,23180.7,12503.5,23177.7,12500.5z"/>
			<path d="M23935.7,13092.5c-15-14-30-25-33-25c-3,1-25,5-49,9c-36,7-43,6-41-8c3-34-13-60-42-66c-18-4-29-13-29-23s-10-22-22-27
				c-29-13-35-43-11-60c17-14,17-14-3-15c-12,0-27-7-34-15c-11-13-10-18,6-30c17-13,18-14,2-15c-13,0-19-9-20-32c-3-53,2-92,12-102
				c6-6,10-22,10-36s6-34,13-44c12-17,14-17,20-2c4,9,14,16,22,16c13,0,15-6,10-30c-5-23-1-35,19-55c14-14,35-25,48-26
				c58-2,102,4,114,16c16,15,69,29,82,21c5-3,16,4,25,15c14,16,14,19,2,19c-21,0-19,6,18,50c35,43,47,49,47,24c0-9,11-25,24-35
				s26-30,30-43c4-18,19-29,53-42c27-10,55-23,64-31c33-27,87-8,87,31c0,21-9,28-44,42c-24,9-44,23-44,31s-12,25-26,38
				c-15,14-24,31-21,39s-1,20-9,26c-8,7-14,18-14,25c0,6-7,26-16,43c-11,23-12,36-5,51c10,17,7,20-16,23c-14,2-29,9-31,17
				c-3,10,1,12,17,8c19-5,21-2,15,16c-3,12-16,38-28,57s-27,47-33,61c-15,34-89,94-96,77c-4-9-6-7-6,3c-1,14-20,29-36,29
				C23964.7,13117.5,23950.7,13106.5,23935.7,13092.5z M24028.7,12975.5c19-16,12-118-11-163c-8-15-13-32-10-37s0-16-8-25
				c-9-12-9-15,0-9c7,4,12,1,12-9c0-11-6-15-18-11c-13,5-22-1-30-18c-6-15-23-27-41-31c-17-4-33-11-36-16c-4-5-22-9-41-9
				c-42,0-94,44-75,63c17,17,13,27-8,27c-34,0-32,16,7,41c20,13,37,29,37,34c0,6,4,16,9,23c4,7,5,23,2,36c-4,17,3,31,32,58
				c20,20,51,43,67,52C23949.7,12999.5,24003.7,12996.5,24028.7,12975.5z M24081.7,12677.5c0-5-4-10-10-10c-5,0-10,5-10,10
				c0,6,5,10,10,10C24077.7,12687.5,24081.7,12683.5,24081.7,12677.5z M23931.7,12653.5c0-3-4-8-10-11c-5-3-10-1-10,4c0,6,5,11,10,11
				C23927.7,12657.5,23931.7,12655.5,23931.7,12653.5z"/>
			<path d="M20771.7,12963.5c0-8,5-18,10-21c6-3,10,1,10,9s-4,18-10,21C20776.7,12975.5,20771.7,12971.5,20771.7,12963.5z"/>
			<path d="M22300.7,12280.5c-13-16-12-17,4-4s21,21,13,21C22315.7,12297.5,22307.7,12289.5,22300.7,12280.5z"/>
			<path d="M22258.7,12250.5c-10-9-8-33,2-33c5,0,11,9,14,20C22279.7,12257.5,22271.7,12263.5,22258.7,12250.5z"/>
		</g>
		</svg>
  );
}

export default Logo;